import React from 'react'
import usePatientTests from '../../hooks/usePatientTests'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Config } from '../../Config';

function LabTestDone({data}) {
  console.log("data in lab test status",data)
  const{
    updateLabTestStatus,
    setReportNote,
    handleFileChange,
    reportNote,
    test_note,
    test_file,
    labTestData
  }= usePatientTests({patientdata:data})
  console.log("labTestData",labTestData)
  return (
   <>
    <div className='row'>
          <div className='col-md-7'>
            <div className='card'>
              <div className='card-body'>
              <h5 className="card-title">Mark test as done</h5>
            
              <div className="form-group mt-3">
                  <label htmlFor="diagnosis">Test note</label>
                  <input type="text" 
                  className="form-control mb-3"                 
                  name="reportNote"
                    value={reportNote}
                     onChange={(e)=>setReportNote(e.target.value)}
                   // onBlur={handleBlurWrapper}
                   />
                </div> 
              <div className="form-group mt-3">

                <input
            type="file"
            accept=".pdf"
             onChange={handleFileChange}
            required
            style={{marginTop:"20px"}}
          />           
           </div>                
                        <div className="mt-3 form-group">
                        <button 
          type="submit"
           onClick={()=>updateLabTestStatus()}
           className="btn btn-primary"
          // disabled={!isFormValid}
           >
            Mark As Done
            {/* {isUpdate?"Update":" Save"} */}
           </button>
           </div>
              </div>
            </div>
          </div>
       <div className='col-md-5'>
       <div className='card'>
              <div className='card-body'>
              <table class="table table-bordered">
  <thead>
    <tr>
   
      <th scope="col">Test Note</th>
      <th scope="col">Report File</th>
   
    </tr>
  </thead>
  <tbody>
   
    <tr>
     
      <td>{test_note}</td>
      <td>
        <a href={`${Config.API_URL_FILE}/LabReports/${test_file}`} target='_blank'>
        <InsertDriveFileIcon style={{ fontSize: 'medium' }} />
        </a>
     
        {/* {test_file} */}
        </td>
    
    </tr>
  
  </tbody>
</table>
        </div>
        </div>
       </div>
        </div>
   </>
  )
}

export default LabTestDone