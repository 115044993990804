export const validateDoctorForm = (formState) => {
    const {
        role,
        first_name,
        last_name,
        mobile_number,
        new_opd_fee,
        old_opd_fee,
        opd_duration,
      } = formState;
    
      let isValid =false
      // Your validation logic here
      if(role=="doctor")
      {
        isValid =
        first_name.trim() !== '' &&
        last_name.trim() !== '' &&
        mobile_number.trim() !== '' &&
        new_opd_fee!== '' &&
        old_opd_fee!== '' &&
        opd_duration!== '';
      }
      else
      {
        isValid =
        first_name.trim() !== '' &&
        last_name.trim() !== '' &&
        mobile_number.trim() !== ''
      }
     
    
      return isValid;
  };
  export const validatePatientForm=(formState)=>{
    const {
      first_name,
      // last_name,
      // mobile_number,
      // new_opd_fee,
      // old_opd_fee,
      // opd_duration,
    } = formState;
  
    // Your validation logic here
    const isValid =
      first_name.trim() !== ''
      // last_name.trim() !== '' &&
      // mobile_number.trim() !== '' &&
      // new_opd_fee!== '' &&
      // old_opd_fee!== '' &&
      // opd_duration!== '';
  
    return isValid;
  };
  export const validateCasePaperForm=(formState)=>{
    const {
      doctor_id,
      // last_name,
      // mobile_number,
      // new_opd_fee,
      // old_opd_fee,
      // opd_duration,
    } = formState;
  
    // Your validation logic here
    const isValid =
    doctor_id!== ''
      // last_name.trim() !== '' &&
      // mobile_number.trim() !== '' &&
      // new_opd_fee!== '' &&
      // old_opd_fee!== '' &&
      // opd_duration!== '';
  
    return isValid;
  };
  export const validateTestForm=(formState)=>{
    const {
      test_name,
      test_price,   
      test_type
    } = formState;
  
    // Your validation logic here
    const isValid =
    test_name.trim() !== '' &&
    //test_price.trim() !== ''  &&
    test_price!== ''  &&
    test_type.trim() !== ''  
    return isValid;
  };
  export const validateDiagnosisForm=(formState)=>{
    const {
      diagnosis,
      advice,       
    } = formState;
  
    // Your validation logic here
    const isValid =
    diagnosis.trim() !== '' &&    
    advice!== ''    
    return isValid;
  };
  export const validateLabTestForm=(formState)=>{
    const {
      test_name,
      test_price,  
      test_description     
    } = formState;
  
    // Your validation logic here
    const isValid =
    test_name!== '' &&    
    test_price!== ''  
    
    return isValid;
  };