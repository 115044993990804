import React from 'react'
import Template from '../Components/Template'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useTestForm from '../hooks/useTestForm';
import { clearForm } from '../utility/formUtils';
import { testColumns } from '../utility/TableColumns';
import DataTable from '../Components/DataTable';


function AddTests() {
  const {
    testData,
      currentPage,
      rowsPerPage,
      totalRows,
      isFormValid,
      isUpdate,
      testInfo,
      initialTestInfo,
      testType,
      setTestInfo,
      setIsUpdate,      
      handleChangeWrapper,
      validateFormWrapper,
      onSubmit,
      handlePageChangeWrapper,
      handleChangeRowsPerPageWrapper,    
      updateTestInfo,
      deleteTestInfo,  
      handleBlurWrapper  
  } = useTestForm();
    const addTestsForm=()=>{
        return(
            <>
            <ToastContainer/>
  <div className="row">
                <div className="col-md-6">
                  <div className="card">
                  <div className='card-body'>
                      <h5 className="card-title">Add Tests</h5>
                      <div className="form-group">
      <label htmlFor="doctor">Select Test Type</label>
      <select
        className="form-control mb-3"
        value={testInfo.test_type}
        onChange={handleChangeWrapper}
        name="test_type"
      >
        <option value="">Test Type</option>
        {testType.map((test) => (
          <option key={test._id} value={test._id}>
            {`${test.method}`}
          </option>
        ))}
      </select>
    </div>
                      <div className="form-group">
                  <label htmlFor="test_name">Test Name</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="test_name"
                  name="test_name"
                  value={testInfo.test_name}
                   onChange={handleChangeWrapper}
                   onBlur={handleBlurWrapper}
                   />
                </div>
                <div className="form-group">
                  <label htmlFor="test_price">Test Price</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="test_price"
                  name="test_price"
                  value={testInfo.test_price}
                   onChange={handleChangeWrapper}
                   onBlur={handleBlurWrapper}
                   />
                </div>
               
                <div className="mt-3 form-group">
          <button 
          type="submit"
          onClick={()=>onSubmit()}
           className="btn btn-primary"
           disabled={!isFormValid}
           >
            {isUpdate?"Update":" Save"}
           </button>
           <button 
          type="submit"
          style={{marginLeft:"10px"}}
          onClick={()=>clearForm(setTestInfo,initialTestInfo,setIsUpdate)}
          className="btn btn-primary" 
           disabled={!isFormValid}
           >
           Reset
           </button>
          </div>
                        </div>
                  </div>
                </div>
                <div className="col-md-6">
              
                  <div className="card">
                    <div className="card-body">
                   
                   <div 
                   className="d-flex justify-content-center align-items-center mb-3 mt-3">

</div>
                   
                   

                      <DataTable
                        data={testData}
                        columns={testColumns}
                        totalRows={totalRows}
                        rpg={rowsPerPage}
                        pg={currentPage}
                        onPageChange={handlePageChangeWrapper}
                        handleChangeRowsPerPage={handleChangeRowsPerPageWrapper}
                        updateData={updateTestInfo}
                        deleteData={deleteTestInfo}
                        isPopupModal={false}                      
                      />
                    </div>
                  </div>
                </div>
              
              </div>
            </>
        )
    }
  return (
    <Template>{addTestsForm()}</Template>
  )
}

export default AddTests