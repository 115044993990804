import { useState, useEffect } from 'react';

import { handleChangeRowsPerPage, handlePageChange } from '../Components/Pagination';
import { handleBlur, handleChange, validateForm } from '../utility/formUtils';
import { validateDiagnosisForm, validateLabTestForm} from '../utility/customValidation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetTestsByTypeQuery } from '../Services/modules/master';
import { useAddPatientLabTestMutation, useCollectLabTestAmountMutation, useGetPatientLabTestQuery, useGetPatientLabTestQueueQuery } from '../Services/modules/patient';
import { Config } from '../Config';
import { io } from 'socket.io-client';

const usePatientLabTestQueue=()=>{
  // const socket = io(Config.API_URL_WEBSOCKET, {
  //   withCredentials: true,
  //   extraHeaders: {
  //     "my-custom-header": "value"
  //   }
  // });
  const socket = io(Config.API_URL_WEBSOCKET, {
    // withCredentials: true,
    reconnection: true,
     reconnectionAttempts: 3,  
     reconnectionDelay: 1000,  
     // extraHeaders: {
     //   "my-custom-header": "value"
     // }
   });
 
    const[labTestQueueData,setLabTestQueueData]=useState([])

  const{
    data:dataLabTestQueue,
    isSuccess:isSuccessLabTestQueue,
    isLoading:isLoadingLabTestQueue,
    error:errorLabTestQueue,
    refetch:refetchLabTestQueue
}=useGetPatientLabTestQueueQuery()
useEffect(()=>{
  console.log("All LabTest Queue",dataLabTestQueue)
  if(isSuccessLabTestQueue)
  {   
  console.log("All LabTest Queue success",dataLabTestQueue) 
    setLabTestQueueData(dataLabTestQueue.data)            
  }
},[dataLabTestQueue])    
useEffect(() => {
  
  // Listen for the 'newCasePaper' socket event 
  socket.on('patientLabTestAddedQueue', (dataCollectAmount) => {
    console.log('Opd patient status change socket', dataCollectAmount);
    refetchLabTestQueue();

    // Trigger data refresh when a new case paper is received
    //refreshOpdData();
   // if (refetchLabTestQueue) {
   //   refetchLabTestQueue();
  //  }
  });
  // socket.on('patientStatusChanged', (data) => {
  //   console.log('get emergenct lab test', data);
  //   // Trigger data refresh when a new case paper is received
  //   //refreshOpdData();
  //   if (refetchLabTestQueue) {
  //     refetchLabTestQueue();
  //   }
  // });

  // Clean up the socket listener when the component unmounts
  return () => {
    socket.off('patientLabTestAddedQueue');  
 //   socket.disconnect();        

  };
}, []);
const labTestQueue = labTestQueueData.map((labtest) => ({
  ...labtest,
  lab_test_money: `${labtest.lab_test_money} | ${labtest.is_emergency_test==true?"Emergency":"Normal"}`,
}));
   
            return{
               
                labTestQueueData:labTestQueue        
            }
}

export default usePatientLabTestQueue;
