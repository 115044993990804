export default build =>
    build.mutation({
        query(payload) {
            console.log("payload",payload)
            return {
                url: '/master/deleteUser',
                method: 'POST',
                body: payload,
               
            };
        },
      //  invalidatesTags: ['allDoctors'],
        invalidatesTags: ['allUsers'],

        
    })
