export default build =>
    build.query({
        tagTypes: ['allPatients'],
        keepUnusedDataFor: 1,
        query(payload) {   
        console.log("In get patientssss")

            console.log("Payloaddd",payload)         
            return {
                url: `/patient/getAllPatients?page_number=${payload.page_number}&page_size=${payload.page_size}`,
                method: 'GET',              
            };
           
        },
       providesTags: ['allPatients']
    })
