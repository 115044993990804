import React from 'react'
import Template from '../Components/Template'

function Home() {
    const HomeScreen=()=>{
      return(
        <>
     
        <div>This is home</div>
       
      
        </>
      )
      
    }
  return (
    <Template>
        {HomeScreen()}
    </Template>
  )
}

export default Home