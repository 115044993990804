import { useState, useEffect } from 'react';

import { handleChangeRowsPerPage, handlePageChange } from '../Components/Pagination';
import { handleBlur, handleChange, validateForm } from '../utility/formUtils';
import { validateDiagnosisForm, validateLabTestForm} from '../utility/customValidation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetTestsByTypeQuery } from '../Services/modules/master';
import { useAddPatientLabTestMutation, useCollectLabTestAmountMutation, useGetPatientLabTestQuery } from '../Services/modules/patient';
import { Config } from '../Config';
import { io } from 'socket.io-client';
import axios from 'axios';
import { useSelector } from 'react-redux';

const usePatientTests=({patientdata})=>{
  console.log("Patient data in use patient test",patientdata)
  const socket = io(Config.API_URL_WEBSOCKET, {
   // withCredentials: true,
   reconnection: true,
    reconnectionAttempts: 3,  
    reconnectionDelay: 1000,  
    // extraHeaders: {
    //   "my-custom-header": "value"
    // }
  });
  const userToken = useSelector(state => state.userData.user_data.token);
  const [uploadProgress, setUploadProgress] = useState(0);
  const userData = useSelector((state) => state.userData.user_data);
  const userRole = userData ? userData.user.role : '';

  const paymentMethods = [
    { _id: 'online', method: 'online' },
    { _id: 'cash', method: 'cash' },
    { _id: 'both', method: 'both' },

    // Add more options as needed
  ];
    const[labTestData,setLabTestData]=useState([])
    const[patientLabTestData,setPatientLabTestData]=useState([])
    const[scanningData,setScanningData]=useState([])
    const[labTestQueueData,setLabTestQueueData]=useState([])

    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [isFormValid, setIsFormValid] = useState(false);
  const [file, setFile] = useState([]);
  const [reportNote, setReportNote] = useState('');

//   const[lab_test_id,setLabTestId]=useState(patientdata?.lab_tests[0]?._id)
// const[test_note,setTestNote]=useState(patientdata?.lab_tests[0]?.test_report_note)
// const[test_file,setTestFile]=useState(patientdata?.lab_tests[0]?.test_report_file)

const[lab_test_id,setLabTestId]=useState("")
const[test_note,setTestNote]=useState("")
const[test_file,setTestFile]=useState("")
const[savedLabTestAmount,setSavedLabTestAmount]=useState([])

       const[patientLabTestInfo,setPatientLabTestInfo]=useState({       
        _id:"",
        visit_id:patientdata.visit_id,
        patient_id:patientdata.patient_id,
        test_details:[],   
        total_payment:""   ,
        test_description:"",
        is_emergency_test:false   
      })
      
      const[collectLabTestMoney,setCollectLabTestMoney]=useState({       
        _id:"",
        lab_test_id:patientdata.lab_test_id,
        collect_total_payment:patientdata.lab_test_money,
        payment_method:"",
        cash_payment:"",
        online_payment:"",     
        online_transaction_number:""         
      })
      const [AddLabTest, 
        { 
        data, 
        isSuccess, 
        isLoading, 
        isError, 
        error }] = useAddPatientLabTestMutation();
        const [CollectLabtestAmount, 
          { 
          data:dataCollectAmount, 
          isSuccess:isSuccessCollectAmount, 
          isLoading:isLoadingCollectAmount, 
          isError:isErrorCollectAmount, 
          error:errorCollectAmount 
        }] = useCollectLabTestAmountMutation();
    const{
        data:dataLabTests=[],
        isSuccess:isSuccessLabTests,
        isLoading:isLaodingLabTests,
        error:errorLabTests,
        refetch
    }=useGetTestsByTypeQuery({page_number:currentPage,page_size:rowsPerPage,test_type:"lab_test"})
    const{
        data:dataScanning=[],
        isSuccess:isSuccessScanning,
        isLoading:isLaodingScanning,
        error:errorScanning,
        refetch:refetchscanning
    }=useGetTestsByTypeQuery({page_number:currentPage,page_size:rowsPerPage,test_type:"scanning"})

    const{
      data:dataPatientLabTest=[],
      isSuccess:isSuccessPatientLabTest,
      isLoading:isLaodingPatientLabTest,
      error:errorPatientLabTest,
      refetch:refetchPatientLabTest
  }=useGetPatientLabTestQuery({page_number:currentPage,page_size:rowsPerPage,patient_id:patientdata.patient_id})

  useEffect(()=>{
    if(isSuccess)
    {
      console.log("In add lab test",data)
      if(data.error==false)
      {
        socket.on('patientLabTestAddedQueue', (data) => {
          
            socket.emit('patientLabTestAddedQueue', data);
           
          });
        socket.on('patientStatusChanged', (data) => {
          
          socket.emit('patientStatusChanged', data);
         
        });
        toast.success(data.message)
      
      }
      if(data.error==true)
      {
        toast.error(data.message)
      }
    
    }
   
    return () => {     
      socket.off('patientLabTestAddedQueue');    
      socket.off('patientStatusChanged');    

     // socket.disconnect();

    };
  },[data])
    useEffect(()=>{
       
if(isSuccessLabTests)
{
   
    console.log("dataLabTests.data",dataLabTests)
    setLabTestData(dataLabTests.data)
}
    },[dataLabTests])
    useEffect(()=>{
        if(isSuccessScanning)
        {
            setScanningData(dataScanning)
        }
            },[dataScanning])
            useEffect(()=>{
       
              if(isSuccessPatientLabTest)
              {                 
                 // console.log("dataLabTests.data",dataPatientLabTest)
              //toast.success(dataPatientLabTest.message)
              socket.on('patientStatusChanged', (dataPatientLabTest) => {
                console.log('New status:', dataPatientLabTest);
                socket.emit('patientStatusChanged', dataPatientLabTest);
               
              });
                  setPatientLabTestData(dataPatientLabTest.data)
              }
              return () => {
                socket.off('patientStatusChanged');
               // socket.disconnect();        
              };
                  },[dataPatientLabTest])
                  useEffect(()=>{
       
                    if(isSuccessCollectAmount)
                    {   
                      console.log("Data after collect lab test amount",dataCollectAmount)
                      setSavedLabTestAmount(dataCollectAmount.updatedMoneyStatus)
                      toast.success(dataCollectAmount.message)                                   
                  //   socket.on('patientStatusChanged', (dataCollectAmount) => {
                  //  //   console.log('New status:', dataCollectAmount);
                  //     socket.emit('patientStatusChanged', dataCollectAmount);
                  //     // Update your user table or trigger any other actions here
                  //   });
                    socket.on('patientLabTestAddedQueue', (dataCollectAmount) => {
        
                      socket.emit('patientLabTestAddedQueue', dataCollectAmount);
                      // Update your user table or trigger any other actions here
                    });
                    return () => {
                     // socket.off('patientStatusChanged');
                      socket.off('patientLabTestAddedQueue');

                      //socket.disconnect();        
                    };                  
                    }
                        },[dataCollectAmount])
                        useEffect(()=>{
                          if(userRole=="lab")
                          {
                            setLabTestId(patientdata?.lab_tests[0]?._id)
                            setTestNote(patientdata?.lab_tests[0]?.test_report_note)
                            setTestFile(patientdata?.lab_tests[0]?.test_report_file)
                          }
                        
                        },[patientdata.lab_tests])
            const validateFormWrapper = () => {
                validateForm(patientLabTestInfo, setIsFormValid,validateLabTestForm);
              };
            const handleChangeWrapper = (e) => {
               // console.log("e.target.name",e.target.name)
              
                handleChange(e, patientLabTestInfo, setPatientLabTestInfo,validateFormWrapper);
                                     
              };
              const handleChangeWrapperCollectPayment = (e) => {
               // console.log("e.target.name",e.target.name)
              
                handleChange(e, collectLabTestMoney, setCollectLabTestMoney,validateFormWrapper);
                if (e.target.name === 'payment_method') {
                  // If the payment_method changes, reset the related fields
                  if(e.target.value=="online")
                  {
                    setCollectLabTestMoney((prevCasePaperInfo) => ({
                      ...prevCasePaperInfo,
                      online_ref_number: '',
                      online_payment: patientdata.lab_test_total_fee,
                      cash_payment: 0,
                    }));
                  }
                  if(e.target.value=="cash")
                  {
                    setCollectLabTestMoney((prevCasePaperInfo) => ({
                      ...prevCasePaperInfo,
                      online_ref_number: '',
                      online_payment: 0,
                      cash_payment: patientdata.lab_test_total_fee,
                    }));
                  }
                  if(e.target.value=="both")
                  {
                    setCollectLabTestMoney((prevCasePaperInfo) => ({
                      ...prevCasePaperInfo,
                      online_ref_number: '',
                      online_payment: '',
                      cash_payment: '',
                    }));
                  }
                
                }  
                                                
              };
           
              const onSubmitLabTest = () => {  
               // console.log("In submit",patientLabTestInfo)
                const updatedPatientLabTestInfo = {
                  ...patientLabTestInfo,
                  test_details: patientLabTestInfo.test_details.map(({ value,label, ...rest }) => ({
                    ...rest,
                    test_name: value,
                  })),
                };
                
                // Remove the 'test_price' key from every object in the 'test_details' array
                updatedPatientLabTestInfo.test_details.forEach((item) => {
                  delete item.label;
                });

                console.log("updatedPatientLabTestInfo",updatedPatientLabTestInfo)
                setPatientLabTestInfo(updatedPatientLabTestInfo);
                AddLabTest({
                               
                  updatedPatientLabTestInfo
                  
                })
             
            }
            const onSubmitLabTestPayment=()=>{
              CollectLabtestAmount(collectLabTestMoney)
            }
            const updateLabTestStatus=async()=>{    
              console.log("notes file",file)
              const formData = new FormData(); 
      
              formData.append('reportNote',reportNote);    
              formData.append('reportFile',file); 
              formData.append('labtest_id',lab_test_id)
              try {
                const response = await axios.post(`${Config.API_URL_LOCAL}patient/updateLabTestStatusDone`, formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userToken}`,
                  },
                  onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setUploadProgress(progress);
                  },
                });
          console.log("response after file upload",response)
          setTestNote(response.data.updatedLabTest.test_report_note)
          setTestFile(response.data.updatedLabTest.test_report_file)
          let data=response.data
          socket.on('patientLabTestAddedQueue', (data) => {
        
            socket.emit('patientLabTestAddedQueue', data);
            // Update your user table or trigger any other actions here
          });
         
          
                // Call refetch when upload is successful
             //   refetch();
           //  refetch()
          
                toast.success(response.data.message);
                setTimeout(()=>{setUploadProgress(0)},2000)
              } catch (error) {
                console.log("image upload error",error)
                toast.error('Error uploading image.');
              }
      
                //  CreateNotes(form)            
          
           }
           const handleFileChange=(e)=>{
            const selectedFile = e.target.files[0];
            // Check if the selected file is a PDF
            if (selectedFile && selectedFile.type === 'application/pdf') {
              setFile(selectedFile);
            } else {
              alert('Please select a PDF file.');
            }
           }

            return{
                labTestData,
                scanningData,
                patientLabTestInfo,
                setPatientLabTestInfo,
                handleChangeWrapper,
                handleChangeWrapperCollectPayment,
                onSubmitLabTest,              
                patientLabTestData,
                onSubmitLabTest,   
                onSubmitLabTestPayment,
                paymentMethods  ,
                collectLabTestMoney, 
                setCollectLabTestMoney ,
                labTestQueueData   ,
                handleFileChange ,
                setReportNote ,
                reportNote,
                updateLabTestStatus   ,
                test_note,
                test_file

            }
}

export default usePatientTests;
