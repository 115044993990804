import React from 'react'
import Template from '../Components/Template'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import DataTable from '../Components/DataTable';
import { labTestQueueColumns, opdQueueColumnsDoctor, opdQueueColumnsReception, opdQueueColumnsTvDisplay } from '../utility/TableColumns';
import usePatientOpdQueue from '../hooks/patientOpdQueue';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
//import Diagnosis from '../Components/Patient/DoctorTabs/PatientDiagnosis';
//import PatientCheckingDetails from '../Components/Patient/PatientCheckingDetails';
//import CollectLabTestAmount from '../Components/Patient/CollectLabtestAmount';
//import usePatientTests from '../hooks/usePatientTests';
import usePatientLabTestQueue from '../hooks/usepatientLabTestQueue';
import LabTestDetails from '../Components/Patient/LabTestDetails';
import LabTestDone from '../Components/Patient/LabTestDone';

function PatientLabTestQueue({data}) {
  const userData = useSelector((state) => state.userData.user_data);
  const user_id=userData.user._id
  const role=userData.user.role
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const doctorIdFromQuery = queryParams.get('doctor_id');
    const {
        labTestQueueData
      } = usePatientLabTestQueue();
    const patientLabTestQueueScreen=()=>{
        return(
            <>
              <ToastContainer />  
              <div className='row'>
             
                <div className='col-md-12'>
                    <div className='card'>
                        <div className='card-body'>
                            <DataTable
                            data={labTestQueueData}
                            // isPopupModalCollectLabTestAmount={true} 
                            // isPopupModal={true}
                        is_lab_test_data={true}                                             
                        isEdit={false}
                        isDelete={role=="doctor"?true:false}
                       
                        isSendInButton={role=="doctor"?true: false}
                        isDoneButton={role=="doctor"?true: false}
                        isWaitingButton={role=="doctor"?true: false}
                        isDiagnosisButton={role=="doctor"?true: false}
                      
                     
                        columns={
                         labTestQueueColumns
                      }
                      
                        childrenLabTestDetails={<LabTestDetails/>}
                        childrenLabTestStstusDone={<LabTestDone/>}
                        isPopupModalLabTestStatusDone={true}

                        isPopupModalLabTestDetails={true}
                            />
                        </div>
                    </div>
                </div>
                </div>         
            
            </>
        )
    }
  return (
   <Template>{patientLabTestQueueScreen()}</Template>
  )
}

export default PatientLabTestQueue