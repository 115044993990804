export default build =>
    build.query({
        tagTypes: ['patientLabTests'],
        keepUnusedDataFor: 1,
        query(payload) {   
        console.log("In get patient diagnosis")           
            return {
                url: `/patient/getPatientLabTest?patient_id=${payload.patient_id}&page=${payload.page_number}&pageSize=${payload.page_size}`,                
                method: 'GET',              
            };
           
        },
       providesTags: ['patientLabTests']
    })
