import React from 'react'
import Template from '../Components/Template'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function OPDLanding() {
    const navigate = useNavigate();
   
  const userData = useSelector((state) => state.userData.user_data);
  console.log("userData",userData.user.role)
  const user_id=userData.user._id
  const doctor_name=userData.user.first_name+" "+userData.user.last_name
  let role=userData.user.role
 
//console.log("User data in OPD landing",userData)
    const opdLandingScreen=()=>{
        return(
            <>
             <div className="row">
             {/* <div className="row"> */}
            {role=='doctor'?  <div className='col-md-4'>
              <div 
        className="card text-center m-3" 
        style={{ width: '18rem',cursor:'pointer' }}
        onClick={()=>navigate(`/opdqueue?doctor_id=${user_id}&doctor_name=${doctor_name}`)}

        >
          <div className="card-body">
            <h5 className="card-title">Patient Queue</h5>
          </div>
        </div>
              </div>:''}
            { role=='receptionist'? 
            <>
            <div className='col-md-4'>
              <Link 
    to='/addpatient'
    style={{textDecoration:'none'}}
    >
        <div className="card text-center m-3" style={{ width: '18rem' }}>
          <div className="card-body">
            <h5 className="card-title">Add Patients</h5>
          </div>
        </div>
        </Link>
              </div>
              <div className='col-md-4'>
              <Link 
    to='/doctorslanding'
    style={{textDecoration:'none'}}
    >
        <div className="card text-center m-3" style={{ width: '18rem' }}>
          <div className="card-body">
            <h5 className="card-title">Patient Queue</h5>
          </div>
        </div>
        </Link>

              </div>
              </>
                :''}
              {/* <div className='col-md-4'>
     
        <div className="card text-center m-3" style={{ width: '18rem' }}>
          <div className="card-body">
            <h5 className="card-title">Reports</h5>
          </div>
        </div>
        </div> */}
      
      </div>
            </>
        )
    }
  return (
    <Template>{opdLandingScreen()}</Template>
  )
}

export default OPDLanding