import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api } from '../Services/api'

import patientVisitData from './PatientVisitsData'
import userData from './UserData'
// import urlData from './RedirectUrlData'
// import productDetails from './ProductDetails'
// import stateNamesData from './StateNamesData'
// import districtNamesData from './DistrictNamesData'
// import areaNamesData from './AreaNamesData'

import storageSession from 'redux-persist/lib/storage/session';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage:storage,
};

const reducers = combineReducers({  
    userData, 
    patientVisitData,
    //  urlData,
    //  productDetails,
    //  stateNamesData,
    //  districtNamesData,
    //  areaNamesData,
     api: api.reducer,
  })
  const persistedReducer = persistReducer(persistConfig, reducers);
export const store = configureStore({
  reducer: persistedReducer ,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(api.middleware)

});

setupListeners(store.dispatch);