import { api } from '../../api'

import signin from './signin'
import signup from './signup'


export const signinApi = api.injectEndpoints({
    endpoints: build => ({
        Signin: signin(build), 
        Signup:signup(build)
       
    }),
    overrideExisting: true,
})
export const { useSigninMutation,useSignupMutation } = signinApi
