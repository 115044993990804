export default build =>
    build.query({
        tagTypes: ['testsByType'],
        keepUnusedDataFor: 1,
        query(payload) {   
        console.log("In get tests")

            console.log("Payloaddd",payload)         
            return {
                url: `/master/getTestsByType?page_number=${payload.page_number}&page_size=${payload.page_size}&test_type=${payload.test_type}`,
                method: 'GET',              
            };
           
        },
       providesTags: ['testsByType']
    })
