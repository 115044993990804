import React from 'react'
import usePatientDiagnosisForm from '../../../hooks/usePatientDiagnosis';
import DataTable from '../../DataTable';
import { diagnosisColumns } from '../../../utility/TableColumns';
import DiagnosisDetails from '../DiagnosisDetails';

function PatientDiagnosis({data}) {
  const{
    diagnosisData,
    currentPage,
    rowsPerPage,
    totalRows,
    isFormValid,
    isUpdate,
    patientDiagnosisInfo,
    handleChangeWrapper,
    handleBlurWrapper,
    validateFormWrapper,
    onSubmit,
    handlePageChangeWrapper,
    handleChangeRowsPerPageWrapper,                    
    setIsUpdate,
    setPatientDiagnosisInfo,
    initialDiagnosisInfo,          
  }= usePatientDiagnosisForm({patientdata:data});
    const patientDiagnosisScreen=()=>{
        return(
            <>
             <div className='row'>
          <div className='col-md-7'>
            <div className='card'>
              <div className='card-body'>
              <h5 className="card-title">Add Diagnosis</h5>
              <div className="form-group">
                  <label htmlFor="diagnosis">Diagnosis</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="diagnosis"
                  name="diagnosis"
                   value={patientDiagnosisInfo.diagnosis}
                    onChange={handleChangeWrapper}
                    onBlur={handleBlurWrapper}
                   />
                </div> 
                <div className="form-group">
                  <label htmlFor="advice">Advice</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="advice"
                  name="advice"
                   value={patientDiagnosisInfo.advice}
                    onChange={handleChangeWrapper}
                    onBlur={handleBlurWrapper}
                   />
                </div> 
                <div className="mt-3 form-group">
                        <div className="form-check">
                      <input type="checkbox"
                       className="form-check-input" 
                       id="termsCheckbox" 
                       name="is_surgery"
                       checked={patientDiagnosisInfo.is_surgery} 
                       onChange={handleChangeWrapper} 
                      />
                      <label className="form-check-label" htmlFor="termsCheckbox">
                        Is Surgery
                      
                      </label>
                      
                    </div>
                        </div>
                        <div className="form-group">
                  <label htmlFor="surgery_details">surgery details</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="surgery_details"
                  name="surgery_details"
                   value={patientDiagnosisInfo.surgery_details}
                    onChange={handleChangeWrapper}
                    onBlur={handleBlurWrapper}
                   />
                </div> 
                        <div className="mt-3 form-group">
                        <button 
          type="submit"
          onClick={()=>onSubmit()}
           className="btn btn-primary"
          // disabled={!isFormValid}
           >
            Save
            {/* {isUpdate?"Update":" Save"} */}
           </button>
           </div>
              </div>
            </div>
          </div>
          <div className='col-md-5'>
          <div className='card'>
    <div className='card-body'>
        <DataTable
        data={diagnosisData}
        columns={diagnosisColumns}
        totalRows={totalRows}
        rpg={rowsPerPage}
        pg={currentPage}
       // updateData={updateDoctorInfo}
       // deleteData={deleteDoctorInfo}
        onPageChange={handlePageChangeWrapper}
        handleChangeRowsPerPage={handleChangeRowsPerPageWrapper}
        is_diagnosis_details={true}   
         isEdit={false}
         isDelete={false}
        children={<DiagnosisDetails/>}
        />
    </div>
</div>
          </div>
        </div>
      
            </>
        )
    }
  return (
    <div>{patientDiagnosisScreen()}</div>
  )
}

export default PatientDiagnosis