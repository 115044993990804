import React from 'react'
import Template from '../Components/Template'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import DataTable from '../Components/DataTable';
import { opdQueueColumnsDoctor, opdQueueColumnsReception, opdQueueColumnsTvDisplay } from '../utility/TableColumns';
import usePatientOpdQueue from '../hooks/patientOpdQueue';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Diagnosis from '../Components/Patient/DoctorTabs/PatientDiagnosis';
import PatientCheckingDetails from '../Components/Patient/DoctorTabs/PatientCheckingDetails';
import CollectLabTestAmount from '../Components/Patient/CollectLabtestAmount';

function PatientOpdQueue() {
  const userData = useSelector((state) => state.userData.user_data);
  const user_id=userData.user._id
  const role=userData.user.role
  const doctor_name=userData.user.first_name+" "+userData.user.last_name

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const doctorIdFromQuery = queryParams.get('doctor_id');
  const doctorNameFromQuery = queryParams.get('doctor_name');
    const {
        opdList,
        doctorList,
        doctorInfo,
        handleChangeWrapper,
        onSubmitChangeOpdPatientStatus  ,
        refetch 
      } = usePatientOpdQueue({doctor_id:doctorIdFromQuery});

    const patientOpdQueueScreen=()=>{
        return(
            <>
              <ToastContainer />  
              <div className='row'>
                <button onClick={refetch}>Refresh</button>
             <div class="alert alert-primary" style={{textAlign:'center'}} role="alert">
  OPD Queue Of Doctor <strong>{doctorNameFromQuery}</strong> 
</div>
                <div className='col-md-12'>
                    <div className='card'>
                        <div className='card-body'>
                            <DataTable
                            data={opdList}
                            isPopupModalCollectLabTestAmount={true}  
                            isPopupModal={true}                    
                        isEdit={false}
                        isDelete={role=="doctor"?false:false}
                        isAcceptButton={
                          role=="doctor"?false:
                          role=="receptionist"||role=="superadmin"?true: false}
                        isSendInButton={role=="doctor" || role=="superadmin" ?true: false}
                        isDoneButton={role=="doctor" || role=="superadmin"?true: false}
                        isWaitingButton={role=="doctor" || role=="superadmin"?true: false}
                        isDiagnosisButton={role=="doctor" || role=="superadmin"?true: false}
                        isCollectLabTestMoneyButton={
                          role=="doctor" || role=="superadmin"?false:
                          role=="receptionist" || role=="superadmin"||
                          role=="superadmin"|| role=="superadmin"?true: false
                        }
                        opdStatus={onSubmitChangeOpdPatientStatus}
                        columns={
                          role=="doctor"||role=="superadmin"?
                        opdQueueColumnsDoctor:
                        role=="receptionist" || role=="superadmin"?
                        opdQueueColumnsReception:
                        role=="tvdisplay" || role=="superadmin"?
                        opdQueueColumnsTvDisplay:[]
                      }
                        children={<PatientCheckingDetails/>}
                        childrenCollectLabPayment={<CollectLabTestAmount/>}
                        
                            />
                        </div>
                    </div>
                </div>
                </div>         
            
            </>
        )
    }
  return (
   <Template>{patientOpdQueueScreen()}</Template>
  )
}

export default PatientOpdQueue