import React from 'react'

function DiagnosisDetails({data}) {
    console.log("Data in diagnosis details",data)
  return (
    <div>
        <p>Diagnosis: {data.diagnosis}</p>
        <p>Advice: {data.advice}</p>
        <p>Surgery: {data.is_surgery}</p>
        <p>Surgery Details: {data.surgery_details}</p>
        <p>Date: {data.created_date}</p>       
    </div>
  )
}

export default DiagnosisDetails