import React,{useState,useEffect} from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Template from '../Components/Template';

import DataTable from '../Components/DataTable';
import { patientColumns } from '../utility/TableColumns';

import usePatientForm from '../hooks/usePatientForm';

import TabsModal from '../Components/TabsModal';
import CasePaper from '../Components/Patient/CasePaper';

function AddPatient() {  
  const {
    patientDataWithFullName,
    currentPage,
    rowsPerPage,
    totalRows,
    isFormValid,
    isUpdate,
    patientInfo,
    handleChangeWrapper,   
    onSubmit,
    handlePageChangeWrapper,
    handleChangeRowsPerPageWrapper,
    updatePatientInfo,
    deletePatientInfo,
    searchTerm,
    setSearchTerm,
    isLoading
  } = usePatientForm();
  console.log("patientDataWithFullName",patientDataWithFullName)
        const AddPatientScreen = () => {
          return (
            <>
              <ToastContainer />           
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Add Patient</h5>
                      <div className="row">
                        <div className="col-md-3">
                          <label htmlFor="first_name">First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="first_name"
                            name="first_name"
                            value={patientInfo.first_name}
                            onChange={handleChangeWrapper}
                          />
                        </div>
                        <div className="col-md-3">
                          <label htmlFor="middle_name">Middle Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="middle_name"
                            name="middle_name"
                            value={patientInfo.middle_name}
                            onChange={handleChangeWrapper}
                          />
                        </div> 
                        <div className="col-md-3">
                          <label htmlFor="last_name">Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="last_name"
                            name="last_name"
                            value={patientInfo.last_name}
                            onChange={handleChangeWrapper}
                          />
                        </div>
                        <div className="col-md-3">
                          <label htmlFor="age">Age</label>
                          <input
                            type="text"
                            className="form-control"
                            id="age"
                            name="age"
                            value={patientInfo.age}
                            onChange={handleChangeWrapper}
                          />
                        </div>
                        <div className="col-md-3">
                          <label htmlFor="mobile_number">Mobile Number</label>
                          <input
                            type="text"
                            className="form-control"
                            id="mobile_number"
                            name="mobile_number"
                            value={patientInfo.mobile_number}
                            onChange={handleChangeWrapper}
                          />
                        </div>
                        <div className="col-md-3">
                          <label htmlFor="occupation">Occupation</label>
                          <input
                            type="text"
                            className="form-control"
                            id="occupation"
                            name="occupation"
                            value={patientInfo.occupation}
                            onChange={handleChangeWrapper}
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="address">Address</label>
                          <input
                            type="text"
                            className="form-control"
                            id="address"
                            name="address"
                            value={patientInfo.address}
                            onChange={handleChangeWrapper}
                          />
                        </div>
                        {/* <div className="col-md-3">
                          <label htmlFor="ref_dr">Reffered Dr Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="ref_dr"
                            name="ref_dr"
                            value={patientInfo.ref_dr}
                            onChange={handleChangeWrapper}
                          />
                        </div> */}
                        {/* Add more form controls similarly */}
                        {/* <div className="mt-3 form-group">
                        <div className="form-check">
                      <input type="checkbox"
                       className="form-check-input" 
                       id="termsCheckbox" 
                       name="is_inssured"
                      checked={patientInfo.is_inssured} 
                      onChange={handleChangeWrapper} />
                      <label className="form-check-label" htmlFor="termsCheckbox">
                        Have an inssurance
                      
                      </label>
                      
                    </div>
                        </div> */}
                        
                      </div>
                      <div className="mt-3 form-group">
                        <button
                          type="submit"
                         
                          onClick={onSubmit}
                          className="btn btn-primary"
                          disabled={!isFormValid || isLoading}
                        >
                          {isLoading?<div class="spinner-border spinner-border-sm" role="status"/>:""}

                          {isUpdate ? "Update" : "Save"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                <div class="alert alert-primary text-center" role="alert">
  All Patients
</div>
                  <div className="card">
                    <div className="card-body">
                   
                   <div 
                   className="d-flex justify-content-center align-items-center mb-3 mt-3">

<input 
class="form-control" 
type="text" 
placeholder="Search" 
value={searchTerm}
style={{ width: "350px" }}
onChange={(e) => setSearchTerm(e.target.value)}
aria-label="Search"/>
</div>
                   
                   

                      <DataTable
                         data={patientDataWithFullName}
                         columns={patientColumns}
                         totalRows={totalRows}
                         rpg={rowsPerPage}
                         pg={currentPage}
                         onPageChange={handlePageChangeWrapper}
                         handleChangeRowsPerPage={handleChangeRowsPerPageWrapper}
                         updateData={updatePatientInfo}
                         deleteData={deletePatientInfo}
                         isPopupModal={true}
                         isCasePaperButton={true}
                       children={<CasePaper/>}
                       is_table_pagination={true}
                       
                      />
                    </div>
                  </div>
                </div>
              
              </div>
            </>
          );
        };
  return (
    <Template>{AddPatientScreen()}</Template>
  )
}

export default AddPatient