import { useState, useEffect } from 'react';
import { useAddPatientMutation, useDeletePatientMutation, useEditPatientMutation, useGetAllPatientsQuery, useReportGetAllPatientQueueQuery, useSearchMutation, useSearchQuery } from '../Services/modules/patient';
import { clearForm, handleChange, validateForm } from '../utility/formUtils';
import { validatePatientForm } from '../utility/customValidation';
import { handleChangeRowsPerPage, handlePageChange } from '../Components/Pagination';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import debounce from 'lodash.debounce';

const useReportPatientGetAllVisits = ({doctor_id}) => {
  const [patientsData, setPatientsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');  
 
 
  const{
    data:dataPatients=[],
    isSuccess:isSuccessPatients,
    isLoading:isLoadingPatients,
    error:errorPatients,
    refetch
}=useReportGetAllPatientQueueQuery({page_number:currentPage,page_size:rowsPerPage,doctor_id:doctor_id})

const [SearchPatient, 
  { 
    data:dataSearchedPatients=[],
    isSuccess:isSuccessSearchedPatients,
    isLoading:isLoadingSearchedPatients,
    error:errorSearchedPatients,   
}] = useSearchMutation();

 
  useEffect(() => {
    if(isSuccessSearchedPatients)
    {
      console.log("searched dataaaa",dataSearchedPatients)
      if(dataSearchedPatients.error)
      {
        return toast.error(dataSearchedPatients.message)
      }

      setPatientsData(dataSearchedPatients.patients)

      //setSearchResults(dataSearchedPatients.patients);
    }
  }, [dataSearchedPatients]);
  useEffect(() => {
    // Initial fetch when component mounts (if needed)
    debouncedFetch(searchTerm);

    // Cleanup function to cancel the debounce on component unmount
    return () => {
      debouncedFetch.cancel();
    };
  }, [searchTerm]);
  useEffect(()=>{
    console.log("All patients data",dataPatients)
    if(isSuccessPatients)
    {
      setPatientsData(dataPatients.data)
      setTotalRows(dataPatients.totalRows);
    }
  },[dataPatients])
  
useEffect(()=>{
  console.log("In useeffect current page change",currentPage)
  refetch()
},[currentPage])

 
  const fetchSearchResults = async (term) => {
    try {     
      setPatientsData([]);

      SearchPatient({searchTerm:term})     
    } catch (error) {
      console.error(error);
    }
  };

  const debouncedFetch = debounce(fetchSearchResults, 500); // Adjust debounce delay as needed

const handlePageChangeWrapper = handlePageChange(setCurrentPage);
const handleChangeRowsPerPageWrapper = handleChangeRowsPerPage(setRowsPerPage, setCurrentPage, refetch);


  return {
    patientsData,
    currentPage,
    rowsPerPage,
    totalRows,   
   // patientInfo,  
    handlePageChangeWrapper,
    handleChangeRowsPerPageWrapper,  
    searchTerm,
    setSearchTerm,
    //isLoading
    // Add other state variables and functions here
  };
};

export default useReportPatientGetAllVisits;
