import { api } from '../../api'
import addPatient from './addPatient'
import addPatientDiagnosis from './addPatientDiagnosis'
import addPatientLabTest from './addPatientLabTest'
import changeOpdPatientStatus from './changeOpdPatientStatus'
import checkCasePaper from './checkCasePaper'
import collectPatientLabTestAmount from './collectPatientLabTestAmount'
import createCasePaper from './createCasePaper'
import deletePatient from './deletePatient'
import deletePatientVisit from './deletePatientVisit'
import editCasePaper from './editCasePaper'
import editPatient from './editPatient'
import getAllPatients from './getAllPatients'
import getCasepaperVisitByDate from './getCasepaperVisitByDate'
import getCasepaperVisits from './getCasepaperVisits'
import getPatientCasepapers from './getPatientCasepapers'
import getPatientDiagnosisByPatientAndDoctor from './getPatientDiagnosisByPatientAndDoctor'
import getPatientHistory from './getPatientHistory'
import getPatientLabTest from './getPatientLabTest'
import getPatientLabTestsQueue from './getPatientLabTestsQueue'
import getPatientQueue from './getPatientQueue'
import reportGetAllPatientQueue from './reportGetAllPatientQueue'
import search from './search'

export const patientApi = api.injectEndpoints({
    endpoints: build => ({
        AddPatient: addPatient(build),  
        EditPatient:editPatient(build),
        DeletePatient:deletePatient(build),
        GetAllPatients:getAllPatients(build),
        CreateCasePaper:createCasePaper(build),
        CheckCasePaper:checkCasePaper(build),
        GetPatientQueue:getPatientQueue(build),
        ChangeOpdPatientStatus:changeOpdPatientStatus(build),
        Search:search(build),
        AddPatientDiagnosis:addPatientDiagnosis(build),
        GetPatientDiagnosisByPatientAndDoctorId:getPatientDiagnosisByPatientAndDoctor(build)   ,      
        AddPatientLabTest:addPatientLabTest(build),
        GetPatientLabTest:getPatientLabTest(build),
        CollectLabTestAmount:collectPatientLabTestAmount(build) ,
        GetPatientLabTestQueue:getPatientLabTestsQueue(build)  ,
        GetPatientCasePapers:getPatientCasepapers(build),
        GetCasepaperVisits:getCasepaperVisits(build) ,
       // GetCasepaperVisitByDate:getCasepaperVisitByDate(build),    
        GetPatientHistory:getPatientHistory(build),
        EditCasePaperVisit:editCasePaper(build),
        DeletePatientVisit:deletePatientVisit(build),
        ReportGetAllPatientQueue:reportGetAllPatientQueue(build)

    }),
    overrideExisting: true,
    //providesTags: ['Research']
})
export const {
    useAddPatientMutation,
    useGetAllPatientsQuery,
    useEditPatientMutation,
    useDeletePatientMutation,
    useCheckCasePaperMutation,
    useCreateCasePaperMutation,
    useGetPatientQueueQuery,
    useChangeOpdPatientStatusMutation,
    useSearchMutation,
    useAddPatientDiagnosisMutation,
    useGetPatientDiagnosisByPatientAndDoctorIdQuery,
    useAddPatientLabTestMutation,
    useGetPatientLabTestQuery,
    useCollectLabTestAmountMutation,
    useGetPatientLabTestQueueQuery,
    useGetPatientCasePapersQuery,
    useGetCasepaperVisitsMutation,
    useGetPatientHistoryMutation,
    useEditCasePaperVisitMutation,
    useDeletePatientVisitMutation,
    useReportGetAllPatientQueueQuery
   // useGetCasepaperVisitByDateQuery
} = patientApi
