const handlePageChange = (setCurrentPage) => (event, newPage) => {
console.log("newPage check",newPage)
  setCurrentPage(newPage);
   // refetch();
  };
  
  const handleChangeRowsPerPage = (setRowsPerPage, setCurrentPage, refetch) => event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
    refetch();
  };
  
  export { handlePageChange, handleChangeRowsPerPage };