import React, { useEffect, useState } from 'react';
import { useSignupMutation } from '../Services/modules/user';
import { updateUserData } from '../Store/UserData';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function SignupUser() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({
    username: '',
    password: '',
    confirmPassword: '',
  });
  const [isFormValid, setIsFormValid] = useState(false);

  const [Signup, { data, isSuccess, isLoading, isError, error }] = useSignupMutation();

  useEffect(() => {
    if (isSuccess) {
      console.log('InSuccess', data);

      if (data.error === true) {
        toast.error(data.message);
        return;
      }
     // dispatch(updateUserData({ data: data }));
      navigate('/signin');
    }
    if (isError) {
      alert('In error');
      console.log(error);
    }
  }, [data]);

  const validateForm = () => {
    const { username, password, confirmPassword } = state;
    if (username !== '' && password !== '' && password === confirmPassword) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  function handleChange(e) {
    const value = e.target.value;

    setState({
      ...state,
      [e.target.name]: value,
    });
//validateForm();
  }

  var payload = {
    username: state.username,
    password: state.password,
  };

  function onSubmit() {
    console.log('in on submit');
    if(state.username=="" || state.password=="" || state.confirmPassword=="")
    {
      return toast.error("Please fill all input fields")
    }
    if(state.password !== state.confirmPassword)
    {
      return toast.error("Password do not matched")

    }
    Signup(payload);
  }

  return (
    <>
      <ToastContainer />

      <div className="App d-flex align-items-center justify-content-center vh-100">
        <div className="card p-4">
          <h2 className="mb-4">Signup</h2>

          <div className="mt-3 form-group">
            <label htmlFor="username">Username</label>
            <input type="text" name="username" onChange={handleChange} className="form-control" id="username" />
          </div>
          <div className="mt-3 form-group">
            <label htmlFor="password">Password</label>
            <input type="password" className="form-control" onChange={handleChange} name="password" id="password" />
          </div>
          <div className="mt-3 form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              className="form-control"
              onChange={handleChange}
              name="confirmPassword"
              id="confirmPassword"
            />
          </div>
          <div className="mt-3 form-group">
            <button type="submit" onClick={onSubmit} className="btn btn-primary">
              Signup
            </button>
          </div>

          <div className="mt-3 text-center">
            <span>Already have an account?</span>
            <a onClick={()=>navigate("/login")} className="ml-2">
              Login
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignupUser;