export default build =>
    build.query({
        tagTypes: ['allCasePapersByPatient'],
        keepUnusedDataFor: 1,
        query(payload) {   
        console.log("In get patientssss")

            console.log("Payloaddd",payload)         
            return {
                url: `/patient/getPatientCasePapers?patient_id=${payload.patient_id}&doctor_id=${payload.doctor_id}`,
                method: 'GET',              
            };
           
        },
       providesTags: ['allCasePapersByPatient']
    })
