export const clearForm = (setFormState, initialFormState,setUpdate) => {
    setFormState(initialFormState);
    setUpdate(false)
  };

  export const handleChange = (e, formState, setFormState,validateForm) => {
    const { name, value, type, checked } = e.target;
    console.log("typeee",type)
    console.log("checked",checked)
    console.log("name",name)
    console.log("value",value)


    setFormState({
      ...formState,
      [name]: type === 'checkbox' ? checked:value
        
     // [name]: value,
    });
    validateForm();
  };
  
  export const handleBlur = (validateForm) => {
    validateForm();
  };
  
  export const validateForm = (formState, setIsFormValid, customValidation) => {
    const isValid = customValidation(formState);
    setIsFormValid(isValid);
  };
  
  