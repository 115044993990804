import { useState, useEffect } from 'react';
import { clearForm, handleBlur, handleChange, validateForm } from '../utility/formUtils';
import { validatePatientForm, validateTestForm } from '../utility/customValidation';
import { handleChangeRowsPerPage, handlePageChange } from '../Components/Pagination';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import debounce from 'lodash.debounce';
import { useAddTestMutation, useDeleteTestMutation, useEditTestMutation, useGetAllTestsQuery } from '../Services/modules/master';

const useTestForm = () => {
  const [testData, setTestData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');  
  const [testInfo, setTestInfo] = useState({
    _id:"",
    test_name: "",
    test_price:"",
    test_type:""  
  });
  const initialTestInfo = {
    test_name: "",
    test_price:"",
    test_type:""  
  };
  const testType = [
     
    { _id: 'lab_test', method: 'lab_test' },
    { _id: 'scanning', method: 'scanning' }, 
  ];
  const{
    data:dataTests=[],
    isSuccess:isSuccessTests,
    isLoading:isLoadingTests,
    error:errorTests,
    refetch
}=useGetAllTestsQuery({page_number:currentPage,page_size:rowsPerPage})

const [AddTest, 
    { 
    data, 
    isSuccess, 
    isLoading, 
    isError, 
    error }] = useAddTestMutation();
    const [EditTest, 
      { 
      data:editData, 
      isSuccess:editSuccess, 
      isLoading:editLoading, 
      isError:editIsError, 
      error:editError 
    }] = useEditTestMutation();
    const [DeleteTest, 
      { 
      data:deleteData, 
      isSuccess:deleteSuccess, 
      isLoading:deleteLoading, 
      isError:deleteIsError, 
      error:deleteError 
    }] = useDeleteTestMutation();
  
    useEffect(() => {
        if(isSuccess)
        {
          console.log("dataaaa",data)
          if(data.error)
          {
  setIsFormValid(false)

            clearForm(setTestInfo,initialTestInfo,setIsUpdate)
            return toast.error(data.message)
          }
            toast.success("Test added successfully")
            clearForm(setTestInfo,initialTestInfo,setIsUpdate)
        }
      }, [data]);
    
      useEffect(()=>{
        console.log("All tests data",dataTests)
        if(isSuccessTests)
        {
          setTestData(dataTests.data)
          setTotalRows(dataTests.totalRows);
        }
      },[dataTests])
      useEffect(()=>{
        if(deleteSuccess)
            {
              console.log("dataaaa",deleteData)
              if(deleteData.error==true)
              {
                 toast.error(deleteData.message)
              }
              else
              {
                toast.success(deleteData.message)
      
              }
                clearForm(setTestInfo,initialTestInfo,setIsUpdate)
            }
      },[deleteData])
      useEffect(()=>{
        if(editSuccess)
            {
              console.log("dataaaa",editData)
              if(editData.error==true)
              {
                 toast.error(editData.message)
              }
              else
              {
                toast.success(editData.message)
      
              }
                clearForm(setTestInfo,initialTestInfo,setIsUpdate)
            }
      },[editData])
    useEffect(()=>{
      console.log("In useeffect current page change",currentPage)
      refetch()
    },[currentPage])

    const handleChangeWrapper = (e) => {
      handleChange(e, testInfo, setTestInfo,validateFormWrapper);
    };
    const handleBlurWrapper = () => {
      handleBlur(() => validateFormWrapper());
    };
    const validateFormWrapper = () => {
      validateForm(testInfo, setIsFormValid,validateTestForm);
    };
    const onSubmit = () => {
      if(isUpdate)
      {
        EditTest(testInfo)
      }
      else
      {
        AddTest(testInfo)
  
      }
    };

    const handlePageChangeWrapper = handlePageChange(setCurrentPage);
const handleChangeRowsPerPageWrapper = handleChangeRowsPerPage(setRowsPerPage, setCurrentPage, refetch);
const updateTestInfo = (dataRow) => {   
  setTestInfo({
    ...testInfo,
    ...dataRow,
   // patientInfo:dataRow
     //first_name:dataRow.first_name,
    // last_name:dataRow.last_name,
    // mobile_number:dataRow.mobile_number,
    // new_opd_fee:dataRow.new_opd_fee,
    // old_opd_fee:dataRow.old_opd_fee,    
    // opd_duration:dataRow.opd_duration ,
    // doctor_id:dataRow._id,
  });
  setIsUpdate(true)
  setIsFormValid(true)
};
const deleteTestInfo=(test_id)=>{

  DeleteTest({test_id})
}
    return {
      testData,
      currentPage,
      rowsPerPage,
      totalRows,
      isFormValid,
      isUpdate,
      testInfo,
      setTestInfo,
      initialTestInfo,
      setIsUpdate,
      testType,
      handleChangeWrapper,
      validateFormWrapper,
      onSubmit,
      handlePageChangeWrapper,
      handleChangeRowsPerPageWrapper,    
      updateTestInfo,
      deleteTestInfo,  
      handleBlurWrapper   
    };
}

export default useTestForm;
