import React from 'react'
import Template from '../Components/Template'
import { useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import useReportPatientGetAllVisits from '../hooks/useReportPatientGetAllVisits';
import DataTable from '../Components/DataTable';
import { opdQueueColumnsDoctor, opdQueueColumnsReception } from '../utility/TableColumns';
import PatientCheckingDetails from '../Components/Patient/DoctorTabs/PatientCheckingDetails';
import { useSelector } from 'react-redux';

function ReportPatientVisits() {
    const userData = useSelector((state) => state.userData.user_data);
    const user_id=userData.user._id
    const role=userData.user.role
    const doctor_name=userData.user.first_name+" "+userData.user.last_name  
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const doctorIdFromQuery = queryParams.get('user_id');
    const doctorNameFromQuery = queryParams.get('doctor_name');
      const {
    patientsData,
    currentPage,
    rowsPerPage,
    totalRows,   
   // patientInfo,  
    handlePageChangeWrapper,
    handleChangeRowsPerPageWrapper,  
    searchTerm,
    setSearchTerm,
        } = useReportPatientGetAllVisits({doctor_id:doctorIdFromQuery});
  
    const ReportPatientVisitsScreen=()=>{
        console.log("patientsdata in report screennn",patientsData)
        return(
<>
<div className='row'>
                {/* <button onClick={refetch}>Refresh</button> */}
             <div class="alert alert-primary" style={{textAlign:'center'}} role="alert">
  OPD Queue Of Doctor <strong>{doctorNameFromQuery}</strong> 
</div>
                <div className='col-md-12'>
                    <div className='card'>
                        <div className='card-body'>
                            {/* <DataTable
                            data={patientsData}
                            isPopupModalCollectLabTestAmount={true}  
                            isPopupModal={true}                    
                        isEdit={false}
                        isDelete={role=="doctor"?true:false}
                        isAcceptButton={
                          role=="doctor"?false:
                          role=="receptionist"||role=="superadmin"?true: false}
                        isSendInButton={role=="doctor" || role=="superadmin" ?true: false}
                        isDoneButton={role=="doctor" || role=="superadmin"?true: false}
                        isWaitingButton={role=="doctor" || role=="superadmin"?true: false}
                        isDiagnosisButton={role=="doctor" || role=="superadmin"?true: false}
                        isCollectLabTestMoneyButton={
                          role=="doctor" || role=="superadmin"?false:
                          role=="receptionist" || role=="superadmin"||
                          role=="superadmin"|| role=="superadmin"?true: false
                        }
                       // opdStatus={onSubmitChangeOpdPatientStatus}
                        columns={
                          role=="doctor"||role=="superadmin"?
                        opdQueueColumnsDoctor:
                        role=="receptionist" || role=="superadmin"?
                        opdQueueColumnsReception:[]
                      }
                         children={<PatientCheckingDetails/>}
                        //childrenCollectLabPayment={<CollectLabTestAmount/>}
                        // totalRows={totalRows}
                        // rpg={rowsPerPage}
                        // pg={currentPage}
                        // onPageChange={handlePageChangeWrapper}
                        // handleChangeRowsPerPage={handleChangeRowsPerPageWrapper}
                            /> */}
                        </div>
                    </div>
                </div>
                </div>   
</>
        )

    }
  return (
    <Template>{ReportPatientVisitsScreen()}</Template>
  )
}

export default ReportPatientVisits