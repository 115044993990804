import { useState, useEffect } from 'react';
import { useGetAllDoctorsQuery } from '../Services/modules/master';
import { handlePageChange } from '../Components/Pagination';
import { handleChange, validateForm } from '../utility/formUtils';
import { validateCasePaperForm } from '../utility/customValidation';
import { useCheckCasePaperMutation, useCreateCasePaperMutation, useGetCasepaperVisitByDateQuery } from '../Services/modules/patient';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import createWebSocket from '../utility/websocket';
import io from 'socket.io-client';
import { Config } from '../Config';
import { useDispatch } from 'react-redux';
import { clearPatientVisitData } from '../Store/PatientVisitsData';

const useCasePaperForm=({patientdata})=>{
  const dispatch=useDispatch()
    //console.log("patientData",patientdata)
    // const socket = io('http://localhost:8000');
    const socket = io(Config.API_URL_WEBSOCKET);

    //const { sendMessage } = createWebSocket();

  const [doctorList, setDoctorList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [totalRows, setTotalRows] = useState(0);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const[casePaperStatusText,setCasePaperStatusText]=useState("")
  const[casepaper_status,setCasePaperStatus]=useState("")
 // const[casepaper_id,setCasePaperId]=useState("")
  const paymentMethods = [
    { _id: 'online', method: 'online' },
    { _id: 'cash', method: 'cash' },
    { _id: 'both', method: 'both' },

    // Add more options as needed
  ];
  const [casePaperInfo, setCasePaperInfo] = useState({
    _id:"",
   // doctor_name: "",
    doctor_id:"",
    patient_id:patientdata._id,
    fee:"",
    payment_method:"",
    online_ref_number:"",
    online_amount:"",
    cash_in_amount:"",
    duration:"",
    ref_dr:"",
    is_inssured: false,
    is_casepaper_expired:false
  });
  const{
    data:dataDoctors=[],
    isSuccess:isSuccessDoctors,
    isLoading:isLoadingDoctors,
    error:errorDoctors,
    refetch
}=useGetAllDoctorsQuery({page_number:currentPage,page_size:rowsPerPage})
const [CheckCasePaper, 
    { 
    data, 
    isSuccess, 
    isLoading, 
    isError, 
    error }] = useCheckCasePaperMutation();
    const [CreateCasePaper, 
        { 
        data:casePaperData, 
        isSuccess:casePaperSuccess, 
        isLoading:casePaperLoading, 
        isError:casePaperIsError, 
        error:casePaperError }] = useCreateCasePaperMutation();
    
      useEffect(()=>{
        dispatch(clearPatientVisitData())
     },[])
useEffect(()=>{
    console.log("All Scheme data",dataDoctors)
    if(isSuccessDoctors)
    {
    
        setDoctorList(dataDoctors.data)
        
    }
  },[dataDoctors])
  useEffect(()=>{
    if(isSuccess)
    {
      console.log("Check case paper details",data)
      setCasePaperStatusText(data.message)
      setCasePaperStatus(data.casepaper_status)
     
        if(data.casepaper_status=="new")
        {
          setCasePaperStatusText(
            data.message
           
            )
            setCasePaperInfo((prevCasePaperInfo) => ({
                ...prevCasePaperInfo,
                fee: data.dr_details.new_opd_fee,
                duration:data.dr_details.opd_duration
              }));
              
        }
        if(data.casepaper_status=="old")
        {
          setCasePaperStatusText(
            data.message+" | "+
            "Case Paper ID- "+
            data.data.case_paper_number+" | "+
            "Expiry Date- "+data.data.end_date
            )
            setCasePaperInfo((prevCasePaperInfo) => ({
                ...prevCasePaperInfo,
                fee: data.dr_details.old_opd_fee,
                duration:data.dr_details.opd_duration
              }));
              
        }

    }
  },[data])
  useEffect(()=>{
    if(casePaperSuccess)
    {
  
      socket.on('newCasePaper', (casePaperData) => {
        
        socket.emit('newCasePaper', casePaperData);
        // Update your user table or trigger any other actions here
      });
        console.log("case paper data on save",casePaperData)
        if(casePaperData.error)
        {
            toast.error(casePaperData.message)
        }
        else
        {
          toast.success(casePaperData.message)
          setCasePaperStatusText(
            "Case Paper ID- "+
            casePaperData.data.casePaper.case_paper_number+" | "+
            "Visit ID- "+casePaperData.data.casePaperVisits.visit_number
            )
           // setCasePaperId(casePaperData.data.casePaperVisits.casepaper_id)
          //  refetchCasepaperVisit()
        }      
    }
    return () => {
      socket.off('newCasePaper');    
    };
  },[casePaperData])
  const handleChangeWrapper = (e) => {
    handleChange(e, casePaperInfo, setCasePaperInfo,validateFormWrapper);
    if (e.target.name === 'payment_method') {
        // If the payment_method changes, reset the related fields
        if(e.target.value=="online")
        {
          setCasePaperInfo((prevCasePaperInfo) => ({
            ...prevCasePaperInfo,
            online_ref_number: '',
            online_amount: casePaperInfo.fee,
            cash_in_amount: '',
          }));
        }
        if(e.target.value=="cash")
        {
          setCasePaperInfo((prevCasePaperInfo) => ({
            ...prevCasePaperInfo,
            online_ref_number: '',
            online_amount: '',
            cash_in_amount: casePaperInfo.fee,
          }));
        }
        if(e.target.value=="both")
        {
          setCasePaperInfo((prevCasePaperInfo) => ({
            ...prevCasePaperInfo,
            online_ref_number: '',
            online_amount: '',
            cash_in_amount: '',
          }));
        }
        // setCasePaperInfo((prevCasePaperInfo) => ({
        //   ...prevCasePaperInfo,
        //   online_ref_number: '',
        //   online_amount: '',
        //   cash_in_amount: '',
        // }));
      } 
      
     
  };
  const validateFormWrapper = () => {
    validateForm(casePaperInfo, setIsFormValid,validateCasePaperForm);
  };
  const handlePageChangeWrapper = handlePageChange(setCurrentPage, refetch);
  const checkAndUpdateCasePaper = () => {
    CheckCasePaper()
  };
  const onSubmitCheckCasePaper = () => { 
    console.log("casePaperInfo",casePaperInfo)  
    if(casePaperInfo.doctor_id=="")
    {
      clearData()
      return toast.error("Please select doctor name")
    }
    

        CheckCasePaper({data:casePaperInfo})       
  }
  const onSubmitCasePaper = () => {  
    if(casePaperInfo.payment_method=="both")
    {
      const totalAmount = parseInt( casePaperInfo.online_amount) + parseInt( casePaperInfo.cash_in_amount);
      if (totalAmount !== parseInt(casePaperInfo.fee)) {
       
       return toast.error('Online and cash amount not matching total fee');
      } 
    }
 
 CreateCasePaper({data:casePaperInfo})       
}
const clearData=()=>{
  setCasePaperInfo((prevCasePaperInfo) => ({
    ...prevCasePaperInfo,
    _id:"",
    // doctor_name: "",
     doctor_id:"",
     patient_id:patientdata._id,
     fee:"",
     payment_method:"",
     online_ref_number:"",
     online_amount:"",
     cash_in_amount:"",
     duration:"",
     ref_dr:"",
     is_inssured: false,
     is_casepaper_expired:false
  }));
}
  
  return {   
    currentPage,
    rowsPerPage,
    totalRows,
    isFormValid,
    isUpdate,
    casePaperInfo,
    doctorList,
    handleChangeWrapper,
    checkAndUpdateCasePaper,
    onSubmitCheckCasePaper,
    paymentMethods,
    onSubmitCasePaper,
    casePaperStatusText,
    casepaper_status,
    isLoading,
    casePaperLoading,
   
    // Add other state variables and functions here
  };

}
export default useCasePaperForm;
