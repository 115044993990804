export default build =>
    build.mutation({
        query(payload) {
            console.log("payload",payload)
            return {
                url: '/patient/getPatientHistory',
                method: 'POST',
                body: payload,
               
            };
        },
      //  invalidatesTags: ['allPatients'],
    })
