import React from 'react'

function LabTestDetails(data) {
    console.log("Data in lab test details component",data)
  
  return (
   <>
   <div className='row'>
  <div className='col-md-12'>
  <table class="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Test Name</th>
      <th scope="col">Price</th>    
    </tr>
  </thead>
  <tbody>
   {data.data.test_details.map((data,index)=>{
    return(
      <>
      <tr>
      <th scope="row">{index+1}</th>
      <td>{data.test_name}</td>
      <td>{data.test_price}</td>    
    </tr>
      </>
    )
   }) 
    }
    <tr>
      <td colSpan={3}>
Test Description: {data.data.test_description}
      </td>
      </tr>
   
  </tbody>
</table>
  </div>
</div>
   </>
  )
}

export default LabTestDetails