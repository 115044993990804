export default build =>
    build.query({
        tagTypes: ['allPatientsQueue'],
        keepUnusedDataFor: 1,
        query(payload) {   
        console.log("In get patient queue")           
            return {
               // url: `/patient/getPatientQueue?doctor_id=${payload.doctor_id}`,                
               url: `/patient/getPatientQueueCurrentDate?doctor_id=${payload.doctor_id}`,                

                method: 'GET',              
            };
           
        },
       providesTags: ['allPatientsQueue']
    })
