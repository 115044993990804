import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'patientVisitData',
    initialState: { patient_visit_data: [] },
    reducers: {
        updatePatientVisitData: (state, { payload: { data } }) => {
            state.patient_visit_data = data
        },
        clearPatientVisitData: (state, { }) => {
            state.patient_visit_data = []
        },
    },
})

export const { updatePatientVisitData, clearPatientVisitData } = slice.actions

export default slice.reducer
