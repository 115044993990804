import { useState, useEffect } from 'react';
import { useDeletePatientVisitMutation, useGetCasepaperVisitsMutation, useGetCasepaperVisitsQuery, useGetPatientCasePapersQuery, useGetPatientHistoryMutation } from '../Services/modules/patient';
import { useDispatch } from 'react-redux';
import { updatePatientVisitData } from '../Store/PatientVisitsData';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const usePatientHistory=({patientdata})=>{
  const dispatch=useDispatch()

   // console.log("patientdata.patient_id",patientdata)
//const[patient_id,setPatientId]=useState(patientdata.patient_id)
const[patient_id,setPatientId]=useState(patientdata?.patient_uid || "")
//const[patient_id,setPatientId]=useState(patientdata._id)

console.log("patient_id",patient_id)
const[casePaperData,setCasepaperData]=useState([])
const[casePaperVisitData,setCasepaperVisitData]=useState([])
const[doctor_id,setDoctorId]=useState("")
const[casepaper_id,setCasePaperId]=useState("")
const[visit_id,setVisitId]=useState("")
const[casePaperDetails,setCasePaperDetails]=useState([])
const[casePaperVisitDetails,setCasePaperVisitDetails]=useState([])
const[diagnosisDetails,setDiagnosisDetails]=useState([])
const[labtestDetails,setLabTestDetails]=useState([])
const[isEditPatientVisit,setIsEditPatientVisit]=useState(false)
const[isDeletePatientVisit,setIsDeletePatientVisit]=useState(false)
const[record_id,setRecordId]=useState("")
const[patientVisitDataForEdit,setPatientVisitDataForEdit]=useState([])
const{
    data:dataCasePapers=[],
    isSuccess:isSuccessCasePapers,
    isLoading:isLaodingCasePapers,
    error:errorCasePapers,
    refetch
}=useGetPatientCasePapersQuery({patient_id:patient_id,doctor_id:doctor_id})
const[GetCasepaperVisits,{
    data:dataCasePaperVisits=[],
    isSuccess:isSuccessCasePaperVisits,
    isLoading:isLaodingCasePaperVisits,
    error:errorCasePaperVisits,
    refetch:refetchVisits
}]=useGetCasepaperVisitsMutation()

const[GetPatientHistory,{
    data:dataPatientHistory=[],
    isSuccess:isSuccessPatientHistory,
    isLoading:isLaodingPatientHistory,
    error:errorPatientHistory,
    refetch:refetchPatientHistory
}]=useGetPatientHistoryMutation()

const [DeletePatientVisit, 
    { 
    data:deleteVisitData, 
    isSuccess:deleteVisitSuccess, 
    isLoading:deleteVisitIsLoading, 
    isError:deleteVisitIsError, 
    error:deleteVisitError }] = useDeletePatientVisitMutation();

useEffect(()=>{
if(isSuccessCasePaperVisits)
{
    setCasepaperVisitData(dataCasePaperVisits.data)
}
},[dataCasePaperVisits])
useEffect(()=>{
    if(isSuccessCasePapers)
    {
        setCasepaperData(dataCasePapers.data)
    }
},[dataCasePapers])
useEffect(()=>{

    if(isSuccessPatientHistory)
    {
    dispatch(updatePatientVisitData({data:dataPatientHistory.data.casePaperVisitDetails}))
   // dispatch(updateUserData({ data: data }))

        console.log("dataPatientHistory",dataPatientHistory.data)

        console.log("dataPatientHistory chjjjjjjjjjj",dataPatientHistory.data.labTest)
        setCasePaperDetails(dataPatientHistory.data.casePaperDetails)
        setCasePaperVisitDetails(dataPatientHistory.data.casePaperVisitDetails)
        setLabTestDetails(dataPatientHistory.data.labTest)

        setDiagnosisDetails(dataPatientHistory.data.diagnosis)
    }
},[dataPatientHistory])
useEffect(()=>{
    if(deleteVisitSuccess)
    {
        if(deleteVisitData.error)
        {
           return toast.error(deleteVisitData.message)
        }
        toast.success(deleteVisitData.message)
   GetPatientHistory({visit_id:visit_id,casepaper_id:casepaper_id})

    }
},[deleteVisitData])
const getPatientVisits=(casepaperid)=>{
    console.log("In get patient visits",casepaperid)
    GetCasepaperVisits({casepaper_id:casepaperid})
}
const getPatientHistory=()=>{
    console.log("In get patient history")
   console.log("casepaper_id",casepaper_id)
   console.log("visit_id",visit_id)
   GetPatientHistory({visit_id:visit_id,casepaper_id:casepaper_id})
}
const handleDoctorSelect=()=>{
   // alert("In refetch")
    refetch()
}
const handleEditPatientVisit=(data)=>{
    setIsEditPatientVisit(true)
    setPatientVisitDataForEdit(data)
}
const handleDeleteConfirmDialogPatientVisit=(data)=>{
    console.log("data in delete visit",data)
    setIsDeletePatientVisit(true)
    setRecordId(data._id);
}
const hideDeletepatientVisitConfirmDialog=()=>{
    setIsDeletePatientVisit(false)

}
const handleDeletePatientVisit = () => {
    DeletePatientVisit({record_id});
    setIsDeletePatientVisit(false);
  };
 

return{
    casePaperData,
    casePaperVisitData,
    setCasePaperId,
    casepaper_id,
    getPatientVisits,
    getPatientHistory,
    setVisitId,
    diagnosisDetails,
    labtestDetails,
    casePaperDetails,
    casePaperVisitDetails,
    setDoctorId,
    doctor_id,
    handleDoctorSelect,
    isEditPatientVisit,
    setIsEditPatientVisit,
    handleEditPatientVisit,
    patientVisitDataForEdit,
    handleDeletePatientVisit,
    handleDeleteConfirmDialogPatientVisit,
    isDeletePatientVisit,
    hideDeletepatientVisitConfirmDialog
   
}

}
export default usePatientHistory;
