import React from 'react'
import Template from '../Components/Template'
import { Link } from 'react-router-dom'

function MasterLanding() {
    const masterLandingScreen=()=>{
        return(
            <>
           
           <div className="d-flex justify-content-center align-items-center">
           <Link 
    to='/adddoctor'
    style={{textDecoration:'none'}}
    >
        <div className="card text-center m-3" style={{ width: '18rem' }}>
          <div className="card-body">
            <h5 className="card-title">Add User</h5>
          </div>
        </div>
        </Link>
        <Link 
    to='/addtests'
    style={{textDecoration:'none'}}
    >
        <div className="card text-center m-3" style={{ width: '18rem' }}>
          <div className="card-body">
            <h5 className="card-title">Add Lab Tests</h5>
          </div>
        </div>
        </Link>

        <div className="card text-center m-3" style={{ width: '18rem' }}>
          <div className="card-body">
            <h5 className="card-title">Add Medicins</h5>
          </div>
        </div>
      </div>
           
        
            </>
        )
       
    }
  return (
    <Template>{masterLandingScreen()}</Template>
  )
}

export default MasterLanding