import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usePatientTests from '../../hooks/usePatientTests';

function CollectLabTestAmount({data}) {
    console.log("Data of collect lab payment",data)
    const{
        paymentMethods,
        collectLabTestMoney,
        setCollectLabTestMoney,
        handleChangeWrapperCollectPayment,
        onSubmitLabTestPayment
      }= usePatientTests({patientdata:data})
  return (
   <>
   <ToastContainer/>
   <div className='row'>
          <div className='col-md-7'>
            <div className='card'>
              <div className='card-body'>
              <h5 className="card-title">Collect Amount rs {collectLabTestMoney.collect_total_payment}</h5>
              <div className="form-group mt-3">
      <label htmlFor="doctor">Select Payment Method</label>
      <select
        className="form-control"
        value={collectLabTestMoney.payment_method}
        onChange={handleChangeWrapperCollectPayment}
        name="payment_method"
      >
        <option value="">Payment Method</option>
        {paymentMethods.map((payment) => (
          <option key={payment._id} value={payment._id}>
            {`${payment.method}`}
          </option>
        ))}
      </select>
    </div>
              <div className="form-group mt-3">
                  <label htmlFor="diagnosis">Cash Rs</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="cash_payment"
                  name="cash_payment"
                   value={collectLabTestMoney.cash_payment}
                    onChange={handleChangeWrapperCollectPayment}
                   // onBlur={handleBlurWrapper}
                   />
                </div> 
                <div className="form-group">
                  <label htmlFor="online_payment">Online Rs</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="online_payment"
                  name="online_payment"
                   value={collectLabTestMoney.online_payment}
                    onChange={handleChangeWrapperCollectPayment}
                   // onBlur={handleBlurWrapper}
                   />
                </div> 
                <div className="form-group mt-3">
                  <label htmlFor="online_transaction_number">Online Transaction Number</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="online_transaction_number"
                  name="online_transaction_number"
                   value={collectLabTestMoney.online_transaction_number}
                    onChange={handleChangeWrapperCollectPayment}
                   // onBlur={handleBlurWrapper}
                   />
                </div> 
               
                     
                        <div className="mt-3 form-group">
                        <button 
          type="submit"
          onClick={()=>onSubmitLabTestPayment()}
           className="btn btn-primary"
          // disabled={!isFormValid}
           >
            Save
            {/* {isUpdate?"Update":" Save"} */}
           </button>
           </div>
              </div>
            </div>
          </div>
          {/* <div className='col-md-5'>
          <div className='card'>
    <div className='card-body'>
        <DataTable
        data={diagnosisData}
        columns={diagnosisColumns}
        totalRows={totalRows}
        rpg={rowsPerPage}
        pg={currentPage}
       // updateData={updateDoctorInfo}
       // deleteData={deleteDoctorInfo}
        onPageChange={handlePageChangeWrapper}
        handleChangeRowsPerPage={handleChangeRowsPerPageWrapper}
        />
    </div>
</div>
          </div> */}
        </div>
   </>
  )
}

export default CollectLabTestAmount