import React, { useState } from 'react';
import { Modal, Nav, Tab, Form, Button } from 'react-bootstrap';

const TabsModal = ({isOpen,setIsOpen,children,modal_title,data,size}) => { 
  return (
    <>
    
      <Modal show={isOpen} onHide={() => setIsOpen(false)} centered  size={size}>
        <Modal.Header closeButton>
          <Modal.Title>{modal_title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '800px', overflowY: 'auto' }}>
          {React.cloneElement(children,{data})}
      
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsOpen(false)}>
            Close
          </Button>
          {/* <Button variant="primary">Save changes</Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TabsModal;