export const Config = {
   
   //   API_URL_LOCAL: 'http://localhost:8000/api/',
   //   API_URL_FILE: 'http://localhost:8000',
   //   API_URL_WEBSOCKET: 'http://localhost:8000',
   
   API_URL_LOCAL: 'https://hmsbackend.bilssagroindia.com/api/',
   API_URL_FILE: 'https://hmsbackend.bilssagroindia.com',
   API_URL_WEBSOCKET: 'https://hmsbackend.bilssagroindia.com',
   
  }
  