
export default build =>
    build.query({
        tagTypes: ['allPatientsLabTestQueue'],
        keepUnusedDataFor: 1,
        query(payload) {   
        console.log("In get patient lab test queue")           
            return {
                url: `/patient/getPatientLabTestsTodo`,                
                method: 'GET',              
            };
           
        },
       providesTags: ['allPatientsLabTestQueue']
    })
