const patientColumns = [
    { key: "action", title: "Action" },
    { key: "opd_no", title: "OPD NO" },   
    { key: "full_name", title: "Patient Name" },   
    { key: "mobile_number", title: "Mobile Number" },   


  ];
  const doctorColumns = [
    { key: "action", title: "Action" },
    { key: "full_name", title: "Doctor Name" },   
    { key: "username", title: "Username" },   
    { key: "new_opd_fee", title: "OPD Fee New" },   
    { key: "old_opd_fee", title: "OPD Fee Old" },   
    { key: "opd_duration", title: "OPD Duration" },   
    { key: "role", title: "Role" },   

   // { key: "last_name", title: "Last Name" },   

  ];

  const opdQueueColumnsReception = [
    { key: "action", title: "Action" },
    { key: "sequence", title: "Number" },   
    { key: "full_name", title: "Patient Name" },   
    { key: "doctor_name", title: "Doctor Name" }, 
    { key: "visit_status", title: "Visit Status" }, 
    { key: "lab_test_money", title: "Lab Test Payment" },       
    // { key: "lab_test_money_status", title: "Lab Test Payment Status" },  
    { key: "visit_date", title: "Date" },    
    { key: "visit_time", title: "Time" },            

  ];
  const opdQueueColumnsDoctor = [
    { key: "action", title: "Action" },
    { key: "sequence", title: "Number" },   
    { key: "full_name", title: "Patient Name" },   
    { key: "doctor_name", title: "Doctor Name" }, 
    { key: "visit_status", title: "Visit Status" }, 
    // { key: "lab_test_money", title: "Lab Test Payment" }, 
    { key: "visit_date", title: "Date" },    
    { key: "visit_time", title: "Time" },       


  ];
  const opdQueueColumnsTvDisplay = [
   // { key: "action", title: "Action" },
    { key: "sequence", title: "Number" },   
    { key: "full_name", title: "Patient Name" },   
    { key: "doctor_name", title: "Doctor Name" }, 
    { key: "visit_status", title: "Visit Status" }, 
    //{ key: "lab_test_money", title: "Lab Test Payment" },       
  ];
  const testColumns = [
    { key: "action", title: "Action" },
    { key: "test_name", title: "Test Name" },   
    { key: "test_price", title: "Test Price" },   
    { key: "test_type", title: "Test Type" },   
  ];
  const diagnosisColumns = [
    { key: "action", title: "Action" },
    { key: "diagnosis", title: "diagnosis" },  
    { key: "created_date", title: "Date" },    
    // { key: "advice", title: "Advice" }, 
    // { key: "is_surgery", title: "Surgery" },   
    // { key: "surgery_details", title: "Surgery Details" },   

   // { key: "test_type", title: "Test Type" },   
  ];
  const labTestColumns = [
    { key: "action", title: "Action" },
    { key: "test_given_date", title: "Date" }, 
    { key: "test_name", title: "Test" },   
    { key: "total_payment", title: "Test Price" },    
  ];

  const labTestQueueColumns = [
    { key: "action", title: "Action" },
    { key: "sequence", title: "Number" },   
    { key: "full_name", title: "Patient Name" },   
    { key: "doctor_name", title: "Doctor Name" }, 
    { key: "test_given_date", title: "Test Given Date" },     
    { key: "test_given_time", title: "Test Given Time" },        
    // { key: "visit_status", title: "Visit Status" }, 
    //{ key: "lab_test_money", title: "Lab Test Payment" },     
    { key: "test_status", title: "Status" }, 
    { key: "test_done_date", title: "Test Done Date" },     
    { key: "test_done_time", title: "Test Done Time" },     
      

  ];

  export {patientColumns,
    doctorColumns,
    opdQueueColumnsDoctor,
    opdQueueColumnsReception,
    opdQueueColumnsTvDisplay,
    testColumns,
    diagnosisColumns,
    labTestColumns,
    labTestQueueColumns
  };