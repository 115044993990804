import { api } from '../../api'
import addDoctor from './addDoctor'
import addTest from './addTest'
import deleteDoctor from './deleteDoctor'
import deleteTest from './deleteTest'
import editDoctor from './editDoctor'
import editTest from './editTest'
import getDoctor from './getDoctor'
import getTests from './getTests'
import getTestsByType from './getTestsByType'
import getUsers from './getUsers'

export const doctorApi = api.injectEndpoints({
    endpoints: build => ({
        AddDoctor: addDoctor(build),  
        GetAllDoctors:getDoctor(build),
        GetAllUsers:getUsers(build),
        EditDoctor:editDoctor(build),
        DeleteDoctor:deleteDoctor(build),
        AddTest:addTest(build),
        EditTest:editTest(build),
        DeleteTest:deleteTest(build),
        GetAllTests:getTests(build),  
        GetTestsByType:getTestsByType(build)    
    }),
    overrideExisting: true,
    //providesTags: ['Research']
})
export const {
    useAddDoctorMutation,
    useEditDoctorMutation,
    useDeleteDoctorMutation,
    useGetAllDoctorsQuery,
    useAddTestMutation,
    useEditTestMutation,
    useDeleteTestMutation,
    useGetAllTestsQuery,
    useGetTestsByTypeQuery,
    useGetAllUsersQuery
} = doctorApi
