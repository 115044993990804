import React from 'react'
import Template from '../Components/Template'
import { Link } from 'react-router-dom'

function PatientLanding() {
    const patientLandingScreen=()=>{
        return(
            <>
           
           <div className="d-flex justify-content-center align-items-center">
           <Link 
    to='/addpatient'
    style={{textDecoration:'none'}}
    >
        <div className="card text-center m-3" style={{ width: '18rem' }}>
          <div className="card-body">
            <h5 className="card-title">Add Patients</h5>
          </div>
        </div>
        </Link>
        <Link 
    to='/doctorslanding'
    style={{textDecoration:'none'}}
    >
        <div className="card text-center m-3" style={{ width: '18rem' }}>
          <div className="card-body">
            <h5 className="card-title">Patient Queue For Display</h5>
          </div>
        </div>
        </Link>
        <Link 
    to='/labtestqueue'
    style={{textDecoration:'none'}}
    >
        <div className="card text-center m-3" style={{ width: '18rem' }}>
          <div className="card-body">
            <h5 className="card-title">Patient Lab Tests</h5>
          </div>
        </div>
        </Link>

      </div>
           
        
            </>
        )
       
    }
  return (
    <Template>{patientLandingScreen()}</Template>
  )
}

export default PatientLanding