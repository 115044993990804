import React from 'react'
import useDoctorForm from '../hooks/useDoctorForm';
import Template from '../Components/Template';
import { useNavigate } from 'react-router-dom';

function DoctorsLanding() {
    const {
        doctorsData,
       
         } = useDoctorForm();
         const navigate = useNavigate();
    const doctorsLandingScreen=()=>{
        console.log("doctorsData in queue",doctorsData)
        return(
            <>
            <div className='row'>
              <div className='col-md-12'>
                <div className='row'>
                {doctorsData.map((data,index)=>{
                  return(
                    <div className="card text-center m-3" 
                    style={{ width: '18rem',cursor:'pointer' }}
                    //onClick={()=>navigate(`/opdqueue?doctor_id=${data._id}`)}
                    onClick={() => openInNewTab(`/opdqueue?doctor_id=${data._id}&doctor_name=${data.first_name+" "+data.last_name}`)}
                    >
                    <div className="card-body">
                      <h5 className="card-title">{data.first_name+" "+data.last_name}</h5>
                    </div>
                  </div>
                  )
                })}
                </div>
              
              </div>
            </div>
            </>
        )
    }
    const openInNewTab = (url) => {
      const win = window.open(url, '_blank');
      if (win) {
        win.focus();
      } else {
        alert('Please allow popups for this website');
      }
    };
  
  return (
<Template>{doctorsLandingScreen()}</Template>
  )
}

export default DoctorsLanding