import { useState, useEffect } from 'react';
import { useAddPatientMutation, useDeletePatientMutation, useEditPatientMutation, useGetAllPatientsQuery, useSearchMutation, useSearchQuery } from '../Services/modules/patient';
import { clearForm, handleChange, validateForm } from '../utility/formUtils';
import { validatePatientForm } from '../utility/customValidation';
import { handleChangeRowsPerPage, handlePageChange } from '../Components/Pagination';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import debounce from 'lodash.debounce';

const usePatientForm = () => {
  const [patientsData, setPatientsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');  
  const [patientInfo, setPatientInfo] = useState({
    _id:"",
    first_name: "",
    middle_name:"",
    last_name:"",
    age:"",
    mobile_number:"",
    occupation:"",
    address:"",
    ref_dr:"",
    is_inssured: false
  });
  const initialPatientInfo = {
    first_name: "",
    middle_name:"",
    last_name: "",
    age:"",
    mobile_number:"",
    occupation:"",
    address:"",
    ref_dr:"",
    is_inssured: false
  };
  const{
    data:dataPatients=[],
    isSuccess:isSuccessPatients,
    isLoading:isLoadingPatients,
    error:errorPatients,
    refetch
}=useGetAllPatientsQuery({page_number:currentPage,page_size:rowsPerPage})

const [SearchPatient, 
  { 
    data:dataSearchedPatients=[],
    isSuccess:isSuccessSearchedPatients,
    isLoading:isLoadingSearchedPatients,
    error:errorSearchedPatients,   
}] = useSearchMutation();

    const [AddPatient, 
      { 
      data, 
      isSuccess, 
      isLoading, 
      isError, 
      error }] = useAddPatientMutation();
      const [EditPatient, 
        { 
        data:editData, 
        isSuccess:editSuccess, 
        isLoading:editLoading, 
        isError:editIsError, 
        error:editError 
      }] = useEditPatientMutation();
      const [DeletePatient, 
        { 
        data:deleteData, 
        isSuccess:deleteSuccess, 
        isLoading:deleteLoading, 
        isError:deleteIsError, 
        error:deleteError 
      }] = useDeletePatientMutation();
        
  useEffect(() => {
    if(isSuccess)
    {
      console.log("dataaaa",data)
      if(data.error)
      {
        return toast.error(data.message)
      }
        toast.success("Patient added successfully")
        clearForm(setPatientInfo,initialPatientInfo,setIsUpdate)
    }
  }, [data]);
  useEffect(() => {
    if(isSuccessSearchedPatients)
    {
      console.log("searched dataaaa",dataSearchedPatients)
      if(dataSearchedPatients.error)
      {
        return toast.error(dataSearchedPatients.message)
      }

      setPatientsData(dataSearchedPatients.patients)

      //setSearchResults(dataSearchedPatients.patients);
    }
  }, [dataSearchedPatients]);
  useEffect(() => {
    // Initial fetch when component mounts (if needed)
    debouncedFetch(searchTerm);

    // Cleanup function to cancel the debounce on component unmount
    return () => {
      debouncedFetch.cancel();
    };
  }, [searchTerm]);
  useEffect(()=>{
    console.log("All patients data",dataPatients)
    if(isSuccessPatients)
    {
        setPatientsData(dataPatients.data)
      setTotalRows(dataPatients.totalRows);
    }
  },[dataPatients])
  useEffect(()=>{
    if(deleteSuccess)
        {
          console.log("dataaaa",deleteData)
          if(deleteData.error==true)
          {
             toast.error(deleteData.message)
          }
          else
          {
            toast.success(deleteData.message)
  
          }
            clearForm(setPatientInfo,initialPatientInfo,setIsUpdate)
        }
  },[deleteData])
  useEffect(()=>{
    if(editSuccess)
        {
          console.log("dataaaa",editData)
          if(editData.error==true)
          {
             toast.error(editData.message)
          }
          else
          {
            toast.success(editData.message)
  
          }
            clearForm(setPatientInfo,initialPatientInfo,setIsUpdate)
        }
  },[editData])
useEffect(()=>{
  console.log("In useeffect current page change",currentPage)
  refetch()
},[currentPage])

  const handleChangeWrapper = (e) => {
    handleChange(e, patientInfo, setPatientInfo,validateFormWrapper);
  };
  const validateFormWrapper = () => {
    validateForm(patientInfo, setIsFormValid,validatePatientForm);
  };
  const onSubmit = () => {
    if(isUpdate)
    {
      EditPatient({data:patientInfo})
    }
    else
    {
      AddPatient({data:patientInfo})

    }
  };
  const fetchSearchResults = async (term) => {
    try {     
      setPatientsData([]);

      SearchPatient({searchTerm:term})     
    } catch (error) {
      console.error(error);
    }
  };

  const debouncedFetch = debounce(fetchSearchResults, 500); // Adjust debounce delay as needed

const handlePageChangeWrapper = handlePageChange(setCurrentPage);
const handleChangeRowsPerPageWrapper = handleChangeRowsPerPage(setRowsPerPage, setCurrentPage, refetch);
const patientDataWithFullName = patientsData.map((patients) => ({
  ...patients,
  full_name: `${patients.first_name} ${patients.middle_name} ${patients.last_name} | ${patients.age}`,
})); 
  const updatePatientInfo = (dataRow) => {   
                    setPatientInfo({
                      ...patientInfo,
                      ...dataRow,
                     // patientInfo:dataRow
                       //first_name:dataRow.first_name,
                      // last_name:dataRow.last_name,
                      // mobile_number:dataRow.mobile_number,
                      // new_opd_fee:dataRow.new_opd_fee,
                      // old_opd_fee:dataRow.old_opd_fee,    
                      // opd_duration:dataRow.opd_duration ,
                      // doctor_id:dataRow._id,
                    });
                    setIsUpdate(true)
                    setIsFormValid(true)
                  };
                  const deletePatientInfo=(patient_id)=>{
             
                    DeletePatient({patient_id})
                  }
  // Other functions and logic related to the patient form

  return {
    patientsData,
    currentPage,
    rowsPerPage,
    totalRows,
    isFormValid,
    isUpdate,
    patientInfo,
    handleChangeWrapper,
    validateFormWrapper,
    onSubmit,
    handlePageChangeWrapper,
    handleChangeRowsPerPageWrapper,
    patientDataWithFullName,
    updatePatientInfo,
    deletePatientInfo,
    searchTerm,
    setSearchTerm,
    isLoading
    // Add other state variables and functions here
  };
};

export default usePatientForm;
