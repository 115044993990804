export default build =>
    build.query({
        tagTypes: ['reportAllPatientsQueue'],
        keepUnusedDataFor: 1,
        query(payload) {   
        console.log("In get patient all patient queue")           
            return {
                url: `/patient/reportGetAllPatientsQueue?page_number=${payload.page_number}&page_size=${payload.page_size}&doctor_id=${payload.doctor_id}`,              
                method: 'GET',              
            };
           
        },
       providesTags: ['reportAllPatientsQueue']
    })
