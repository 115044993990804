import React, { useEffect, useState } from 'react'
import { addClassToBody, removeClassFromBody } from '../assets/js/toggle';
import { useDispatch, useSelector } from 'react-redux';
import { clearUserData } from '../Store/UserData';
import { useNavigate } from 'react-router-dom';

function Header() {
  const navigate=useNavigate()
  const dispatch=useDispatch()
  const userData = useSelector((state) => state.userData.user_data);
  const userRole = userData ? userData.user.role : '';

  const [isToggled, setIsToggled] = useState(false);
  useEffect(() => {
    if (isToggled) {
      addClassToBody();
    } else {
      removeClassFromBody();
    }
  }, [isToggled]);
  
  const handleToggle = () => {
    setIsToggled(!isToggled);
  };
  const logout=()=>{
    dispatch(clearUserData())
    navigate("/")
   
  }
  return (
   <>
    <header id="header" class="header fixed-top d-flex align-items-center">

<div class="d-flex align-items-center justify-content-between">
  <a href="index.html" class="logo d-flex align-items-center">
    <img src="assets/img/logo.png" alt=""/>
    <span class="d-none d-lg-block">HMS</span>
  </a>
  <i class="bi bi-list toggle-sidebar-btn" onClick={()=>handleToggle()}/>
  
</div>


<nav class="header-nav ms-auto">
  <ul class="d-flex align-items-center">
  <li class="nav-item dropdown pe-3">

      <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
        {/* <img src="assets/img/profile-img.jpg" alt="Profile" class="rounded-circle"/> */}
        <span class="d-none d-md-block dropdown-toggle ps-2">{userRole}</span>
      </a>

      <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
        <li class="dropdown-header">
          <h6>{userRole}</h6>
          <span>Deccan Malati</span>
        </li>
        <li>
          <hr class="dropdown-divider"/>
        </li>

        <li>
          <a class="dropdown-item d-flex align-items-center" 
          href="#">
            <i class="bi bi-person"></i>
            <span>My Profile</span>
          </a>
        </li>
        <li>
          <hr class="dropdown-divider"/>
        </li>

        <li>
          <a class="dropdown-item d-flex align-items-center" href="#">
            <i class="bi bi-gear"></i>
            <span>Account Settings</span>
          </a>
        </li>
        <li>
          <hr class="dropdown-divider"/>
        </li>

        {/* <li>
          <a class="dropdown-item d-flex align-items-center" href="pages-faq.html">
            <i class="bi bi-question-circle"></i>
            <span>Need Help?</span>
          </a>
        </li> */}
        <li>
          <hr class="dropdown-divider"/>
        </li>

        <li>
          <a class="dropdown-item d-flex align-items-center" 
            onClick={()=>logout()}
            style={{cursor:'pointer'}}
            >
            <i class="bi bi-box-arrow-right"></i>
            <span>Sign Out</span>
          </a>
        </li>

      </ul>
    </li>

  </ul>
</nav>

</header>
   </>
  )
}

export default Header