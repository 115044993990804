export default build =>
    build.mutation({
       
        query(payload) 
        {   
        console.log("In get case paper visits",payload)

            console.log("Payloaddd",payload)         
            return {
                url: `/patient/getCasePaperVisits`,
                method: 'POST',    
                body: payload,          
            };
           
        },
       providesTags: ['casePaperVisits']
    })
