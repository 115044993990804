export default build =>
    build.query({
        tagTypes: ['allDoctors'],
        keepUnusedDataFor: 1,
        query(payload) {   
        console.log("In get doctors")

            console.log("Payloaddd",payload)         
            return {
                url: `/master/getAllDoctors?page_number=${payload.page_number}&page_size=${payload.page_size}&role=${payload.role}`,
                method: 'GET',              
            };
           
        },
       providesTags: ['allDoctors']
    })
