import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import useCasePaperForm from '../../hooks/useCasePaperForm';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PatientHistory from './DoctorTabs/PatientHistory';

function CasePaper({data}) {
    console.log("PatientData in casepaper",data)
     const { _id, ...patientDataWithoutId } = data;
     const updatedData = { patient_uid: _id, ...patientDataWithoutId };
    const {
        currentPage,
        rowsPerPage,
        totalRows,
        isFormValid,
        isUpdate,
        casePaperInfo,
        doctorList,
        handleChangeWrapper,
        checkAndUpdateCasePaper,
        onSubmitCheckCasePaper,
        paymentMethods,
        onSubmitCasePaper,
        casePaperStatusText,
        casepaper_status,
        isLoading,
        casePaperLoading,
        
      } = useCasePaperForm({patientdata:data});
      
  return (
    <>
    <ToastContainer/>
        <Tabs
      defaultActiveKey="home"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="home" title="Get in OPD">
       <div className='row'>
       <div className="form-group">
      
                  <label htmlFor="weight">Select Doctor</label>
                  <select
        className="form-control mb-3 mt-1" // Bootstrap class for styling
        value={casePaperInfo.doctor_id}
        onChange={handleChangeWrapper}
       // onBlur={handleBlur}
        name="doctor_id"
      >
        <option value="">Select Doctor</option>
        {doctorList?.map((option, index) => (
          <option key={option._id} value={option._id}>
            {option.first_name+" "+option.last_name}
          </option>
        ))}
      </select>                
                </div>
                <div className="mb-3 form-group">
                        <button
                          type="submit"
                          onClick={onSubmitCheckCasePaper}
                          className="btn btn-primary"
                          disabled={isLoading}
                        >
                          {isLoading?<div class="spinner-border spinner-border-sm" role="status"/>:""}
                          Check Case Paper
                        </button>
                      </div>
                    { casePaperStatusText!==""?
                    
                     <div class={
                      casepaper_status=="new"?"alert alert-danger":"alert alert-success"
                      } role="alert">
                      {casePaperStatusText}
</div>:""}
                <div className="form-group">
                          <label htmlFor="fee">Fee</label>
                          <input
                            type="text"
                            className="form-control"
                            id="fee"
                            name="fee"
                            value={casePaperInfo.fee}
                            onChange={handleChangeWrapper}
                          />
                        </div>
                        <div className="form-group mt-3">
                          <label htmlFor="first_name">Case Paper Duration</label>
                          <input
                            type="text"
                            className="form-control"
                            id="duration"
                            name="duration"
                            value={casePaperInfo.duration}
                            onChange={handleChangeWrapper}
                          />
                        </div>
                        <div className="form-group mt-3">
      <label htmlFor="doctor">Select Payment Method</label>
      <select
        className="form-control"
        value={casePaperInfo.payment_method}
        onChange={handleChangeWrapper}
        name="payment_method"
      >
        <option value="">Payment Method</option>
        {paymentMethods.map((payment) => (
          <option key={payment._id} value={payment._id}>
            {`${payment.method}`}
          </option>
        ))}
      </select>
    </div>
    <div className="form-group mt-3">
                          <label htmlFor="first_name">Online Reference Number</label>
                          <input
                            type="text"
                            className="form-control"
                            id="online_ref_number"
                            name="online_ref_number"
                            disabled={casePaperInfo.payment_method !== 'online' && casePaperInfo.payment_method !== 'both'}
                            value={casePaperInfo.online_ref_number}
                            onChange={handleChangeWrapper}
                          />
                        </div>
                        <div className="form-group mt-3">
                          <label htmlFor="first_name">Online Amount</label>
                          <input
                            type="text"
                            className="form-control"
                            id="online_amount"
                            name="online_amount"
                            disabled={casePaperInfo.payment_method !== 'online' && casePaperInfo.payment_method !== 'both'}
                            value={casePaperInfo.online_amount}
                            onChange={handleChangeWrapper}
                          />
                        </div>
                        <div className="form-group mt-3">
                          <label htmlFor="first_name">Cash Amount</label>
                          <input
                            type="text"
                            className="form-control"
                            id="cash_in_amount"
                            name="cash_in_amount"
                            disabled={casePaperInfo.payment_method !== 'cash' && casePaperInfo.payment_method !== 'both'}
                            value={casePaperInfo.cash_in_amount}
                            onChange={handleChangeWrapper}
                          />
                        </div>
                        <div className="form-group mt-3">
                          <label htmlFor="first_name">Reference Doctor</label>
                          <input
                            type="text"
                            className="form-control"
                            id="ref_dr"
                            name="ref_dr"
                            value={casePaperInfo.ref_dr}
                            onChange={handleChangeWrapper}
                          />
                        </div>
                        <div className="form-group mt-3">
                        <div className="form-check">
                      <input type="checkbox"
                       className="form-check-input"                       
                       name="is_inssured"
                      checked={casePaperInfo.is_inssured} 
                      onChange={handleChangeWrapper} />
                      <label className="form-check-label" 
                      htmlFor="termsCheckbox">
                        Have an inssurance
                      
                      </label>
                      
                    </div>
                        </div>
                        <div className="mt-3 form-group">
                        <button
                          type="submit"
                          onClick={onSubmitCasePaper}
                          className="btn btn-success"
                         // disabled={!isFormValid}
                         disabled={casePaperLoading}

                        >
                          {casePaperLoading?<div class="spinner-border spinner-border-sm" role="status"/>:""}

                          Create OPD
                        </button>
                      </div>
                        

       </div>
   
      </Tab>
      <Tab eventKey="history" title="History">
      <PatientHistory data={updatedData} doctorList={doctorList}/>

      </Tab>
     
    </Tabs>
                                                                        
    </>
  )
}

export default CasePaper