import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Home from './Pages/Home';
import Login from './Pages/Login';
import { useSelector } from 'react-redux';
import AddPatient from './Pages/AddPatient';
import PatientLanding from './Pages/PatientLanding';
import MasterLanding from './Pages/MasterLanding';
import AddDoctor from './Pages/AddDoctor';
import PatientOpdQueue from './Pages/PatientOpdQueue';
import DoctorsLanding from './Pages/DoctorsLanding';
import SignupUser from './Pages/SignupUser';
import OPDLanding from './Pages/OPDLanding';
import PatientDiagnosisDetails from './Pages/patientDiagnosisDetails';
import AddTests from './Pages/AddTests';
import PatientLabTestQueue from './Pages/PatientLabTestQueue';
import ReportDoctorLanding from './Pages/ReportDoctorLanding';
import ReportPatientVisits from './Pages/ReportPatientVisits';

function ProtectedRoute({
  isAllowed,
  allowedRoles = [],
  disallowedRoles = [],
  redirectPath = '/',
  element,
}) {
  const userData = useSelector((state) => state.userData.user_data);
  const userRole = userData ? userData.user.role : '';

  if (
    !isAllowed ||
    (allowedRoles.length > 0 && !allowedRoles.includes(userRole)) ||
    (disallowedRoles.length > 0 && disallowedRoles.includes(userRole))
  ) {
    return <Navigate to={redirectPath} replace />;
  }

  return React.cloneElement(element, {});
}

function AppRoutes() {
  const userData = useSelector((state) => state.userData.user_data);
  const isLoggedin = userData ? userData.user.is_user_registered : false;

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/signup" element={<SignupUser />} />
      <Route path="/signin" element={<Login />} />

      {/* Protected routes */}
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute
            isAllowed={isLoggedin}
            allowedRoles={['superadmin', 'doctor','tvdisplay','receptionist','lab']}
            element={<Home />}
          />
        }
      />
      <Route
        path="/patientlanding"
        element={
          <ProtectedRoute
            isAllowed={isLoggedin}
            allowedRoles={['superadmin','tvdisplay','receptionist','lab']}
            disallowedRoles={['doctor']}
            element={<PatientLanding />}
          />
        }
      />
       <Route
        path="/opdlanding"
        element={
          <ProtectedRoute
            isAllowed={isLoggedin}
            allowedRoles={['doctor','receptionist']}
            disallowedRoles={['superadmin']}
            element={<OPDLanding />}
          />
        }
      />
             <Route
        path="/patientdiagnosis"
        element={
          <ProtectedRoute
            isAllowed={isLoggedin}
            allowedRoles={['doctor']}
            disallowedRoles={['superadmin']}
            element={<PatientDiagnosisDetails />}
          />
        }
      />
      <Route path="/addpatient" 
      element={
        <ProtectedRoute
            isAllowed={isLoggedin}
            allowedRoles={['superadmin','receptionist']}
            disallowedRoles={['doctor']}
            element={<AddPatient />}
          />
      } 
      />
      <Route 
      path="/opdqueue" 
      element={
        <ProtectedRoute
        isAllowed={isLoggedin}
        allowedRoles={['doctor','receptionist','superadmin','tvdisplay']}
        disallowedRoles={['']}
        element={<PatientOpdQueue />}
      />     
    } 
      />
       <Route 
      path="/labtestqueue" 
      element={
        <ProtectedRoute
        isAllowed={isLoggedin}
        allowedRoles={['doctor','receptionist','superadmin','lab']}
        disallowedRoles={['']}
        element={<PatientLabTestQueue />}
      />     
    } 
      />
       <Route 
      path="/addtests" 
      element={
        <ProtectedRoute
        isAllowed={isLoggedin}
        allowedRoles={['superadmin']}
        disallowedRoles={['']}
        element={<AddTests />}
      />
     
    } 
      />
      <Route path="/doctorslanding" element={<DoctorsLanding />} />
      <Route path="/reportdoctorlanding" element={<ReportDoctorLanding />} />
      <Route 
      path="/reportpatientvisits" 
      element={
        <ProtectedRoute
        isAllowed={isLoggedin}
        allowedRoles={['doctor','receptionist','superadmin','tvdisplay']}
        disallowedRoles={['']}
        element={<ReportPatientVisits />}
      />     
    } 
      />
      {/* <Route path="/reportpatientvisits" element={<ReportPatientVisits />} /> */}
      <Route path="/reportopdcollection" element={<ReportDoctorLanding />} />

      <Route path="/masterlanding" element={<MasterLanding />} />
      <Route path="/adddoctor" element={<AddDoctor />} />
    </Routes>
  );
}

export default AppRoutes;