import React from 'react'
import PatientDiagnosis from './PatientDiagnosis'
import PatientLabTest from './PatientLabTest'
import { Tab, Tabs } from 'react-bootstrap'
import PatientHistory from './PatientHistory'

function PatientCheckingDetails({data}) {
  const { patient_id, ...patientDataWithoutId } = data;
  const updatedData = { patient_uid: patient_id, ...patientDataWithoutId };
  return (
   <>
     <Tabs
      defaultActiveKey="home"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
       <Tab eventKey="home" title="Diagnosis">
   <PatientDiagnosis data={data}/>
   <PatientLabTest data={data}/>
   </Tab>
   <Tab eventKey="history" title="History">
    <PatientHistory data={updatedData}/>
   {/* <PatientDiagnosis data={data}/>
   <PatientLabTest data={data}/> */}
   </Tab>
   </Tabs>
   </>
  )
}

export default PatientCheckingDetails