export default build =>
    build.mutation({
        query(payload) {
          //  console.log("payload",payload)
            return {
                url: '/patient/deletePatientVisit',
                method: 'POST',
                body: payload,
               
            };
        },
       // invalidatesTags: ['allPatients'],
    })
