import React,{useState,useEffect} from 'react'
import Template from '../Components/Template'
import DataTable from '../Components/DataTable'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { doctorColumns } from '../utility/TableColumns';
import useDoctorForm from '../hooks/useDoctorForm';
import { clearForm } from '../utility/formUtils';

function AddDoctor() {
  const {
 doctorsData,
 usersData,

 currentPage,
 rowsPerPage,
 totalRows,
 isFormValid,
 isUpdate,
 doctor_info,
 handleChangeWrapper,
 handleBlurWrapper,
 validateFormWrapper,
 onSubmit,
 handlePageChangeWrapper,
 handleChangeRowsPerPageWrapper,
 doctorsDataWithFullName,
 usersDataWithFullName,
 updateDoctorInfo,
 deleteDoctorInfo,
 setIsUpdate,
 setDoctorInfo,
 initialDoctorInfo,
 userRole
  } = useDoctorForm();
    const addDoctorScreen=()=>{
        return(
            <>
            <ToastContainer/>
            <div className='row'>          
            <div class="alert alert-primary text-center" role="alert">
  Add User
</div>
                <div className='col-md-6'>
                    <div className='card'>
                        <div className='card-body'>
                      <h5 className="card-title">Add Doctor</h5>
                      <div className="form-group">
      <label htmlFor="doctor">Select Role</label>
      <select
        className="form-control mb-3"
        value={doctor_info.role}
        onChange={handleChangeWrapper}
        name="role"
      >
        <option value="">User Role</option>
        {userRole.map((role) => (
          <option key={role._id} value={role._id}>
            {`${role.method}`}
          </option>
        ))}
      </select>
    </div>
                      <div className="form-group">
                  <label htmlFor="first_name">First Name</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="first_name"
                  name="first_name"
                  value={doctor_info.first_name}
                   onChange={handleChangeWrapper}
                   onBlur={handleBlurWrapper}
                   />
                </div>
                <div className="form-group">
                  <label htmlFor="last_name">Last Name</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="last_name"
                  name="last_name"
                  value={doctor_info.last_name}
                   onChange={handleChangeWrapper}
                   onBlur={handleBlurWrapper}
                   />
                </div>
                <div className="form-group">
                  <label htmlFor="mobile_number">Mobile Number</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="mobile_number"
                  name="mobile_number"
                  value={doctor_info.mobile_number}
                   onChange={handleChangeWrapper}
                   onBlur={handleBlurWrapper}
                   />
                </div>
              { doctor_info.role=="doctor"? 
              <>
              <div className="form-group">
                  <label htmlFor="new_opd_fee">New OPD Fee</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="new_opd_fee"
                  name="new_opd_fee"
                  value={doctor_info.new_opd_fee}
                   onChange={handleChangeWrapper}
                   onBlur={handleBlurWrapper}
                   />
                </div>
                 <div className="form-group">
                  <label htmlFor="old_opd_fee">Old OPD Fee</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="old_opd_fee"
                  name="old_opd_fee"
                  value={doctor_info.old_opd_fee}
                   onChange={handleChangeWrapper}
                   onBlur={handleBlurWrapper}
                   />
                </div>
                 <div className="form-group">
                  <label htmlFor="opd_duration">OPD Duration</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="opd_duration"
                  name="opd_duration"
                  value={doctor_info.opd_duration}
                   onChange={handleChangeWrapper}
                   onBlur={handleBlurWrapper}
                   />
                </div>
              </>:""
              }
                <div className="mt-3 form-group">
          <button 
          type="submit"
          onClick={()=>onSubmit()}
           className="btn btn-primary"
           disabled={!isFormValid}
           >
            {isUpdate?"Update":" Save"}
           </button>
           <button 
          type="submit"
          style={{marginLeft:"10px"}}
          onClick={()=>clearForm(setDoctorInfo,initialDoctorInfo,setIsUpdate)}
          className="btn btn-primary" 
           disabled={!isFormValid}
           >
           Reset
           </button>
          </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                <div className='card'>
    <div className='card-body'>
        <DataTable
        data={usersDataWithFullName}
        columns={doctorColumns}
        totalRows={totalRows}
        rpg={rowsPerPage}
        pg={currentPage}
        updateData={updateDoctorInfo}
        deleteData={deleteDoctorInfo}
        onPageChange={handlePageChangeWrapper}
        handleChangeRowsPerPage={handleChangeRowsPerPageWrapper}
        />
    </div>
</div>
                </div>

            </div>
            </>
        )
    }
  return (
    <Template>{addDoctorScreen()}</Template>
  )
}

export default AddDoctor