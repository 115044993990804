// import logo from './logo.svg';
// import './App.css';
import React,{useEffect} from 'react'

import AppRoutes from './AppRoutes';

import './assets/vendor/bootstrap/css/bootstrap.min.css'
//import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/vendor/bootstrap-icons/bootstrap-icons.css'

import './assets/css/style.css'


import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store } from './Store';
import { persistStore } from 'redux-persist';
//import persistStore from 'redux-persist/es/persistStore';
//import { persistStore } from 'redux-persist'
//import 'bootstrap/dist/css/bootstrap.min.css';

let persistor=persistStore(store)
function App() {
 
  return (
    <>
<Provider store={store}>
<PersistGate loading={null}
 persistor={persistor}>
  </PersistGate>
   <div className="App">
   <AppRoutes/>

   </div>


</Provider>

    </>
  );
}

export default App;
