export default build =>
    build.query({
        tagTypes: ['allUsers'],
        keepUnusedDataFor: 1,
        query(payload) {   
        console.log("In get users")

            console.log("Payloaddd",payload)         
            return {
                url: `/master/getAllUsers?page_number=${payload.page_number}&page_size=${payload.page_size}&role=${payload.role}`,
                method: 'GET',              
            };
           
        },
       providesTags: ['allUsers']
    })
