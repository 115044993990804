import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ConfirmationModal from './ConfirmationModal';
import TabsModal from './TabsModal';
import { useSelector } from 'react-redux';

const DataTable = ({ 
  data, 
  columns, 
  updateData, 
  deleteData,
  opdStatus,//function  
   isInsidePage,
    insideLink,
    isCasePaperButton,
    isAcceptButton,
    isSendInButton,
    isDoneButton,
    isRejectButton,
    isWaitingButton,
    isDiagnosisButton,
    isCollectLabTestMoneyButton,
     isEdit = true, 
     isDelete = true, 
     onPageChange, 
     totalRows,
     rpg,
     pg,
     handleChangeRowsPerPage,
     isPopupModal, 
     isPopupModalCollectLabTestAmount,  
     isPopupModalLabTestDetails,  
     isPopupModalLabTestStatusDone,
     children,
     childrenCollectLabPayment,
     childrenLabTestDetails,
     childrenLabTestStstusDone,
     is_lab_test_data,    
     is_diagnosis_details,
     is_table_pagination 

     }) => {
      const userData = useSelector((state) => state.userData.user_data);      
      const role=userData?.user?.role
  const [isConfirmDialog, setIsConfirmDialog] = useState(false);
  const [record_id, setRecordId] = useState();
  const [isModal, setIsModal] = useState(false);
  const [isModalLabTestPaymentCollection, setIsModalLabTestPaymentCollection] = useState(false);
  const [isModalLabTestDetails, setIsModalLabTestDetails] = useState(false);
  const [isModalLabTestStatusDone, setIsModalLabTestStatusDone] = useState(false);
 
  const [isModalDiagnosisDetails, setIsModalDiagnosisDetails] = useState(false);

const[modalTitle,setModalTitle]=useState("")
const[datarow,setDataRow]=useState()
const[test_description,setTestDescription]=useState("")
console.log("Data in table lab test details",data[0])

  const handleEdit = (record) => {
    console.log("Record in edit", record);
    updateData(record);
  };

  const hideDialog = () => {
    setIsConfirmDialog(false);
  };

  const confirmDialogOpen = (dataRow) => {
    setIsConfirmDialog(true);
    setRecordId(dataRow._id);
  };

  const handleDelete = () => {
    deleteData(record_id);
    setIsConfirmDialog(false);
  };

  const handleNavigate = (dataRow) => {
    insideLink(dataRow);
  };
  const handleModalPopup=(dataRow)=>{
    console.log("In handleModalPopup")
    setIsModal(true)
    setDataRow(dataRow)
    setModalTitle("Patient Name-"+ dataRow.first_name+" "+dataRow.middle_name+" "+dataRow.last_name)
  }
  const handleAccept=(dataRow)=>{
    console.log("In handle accept",dataRow)

      opdStatus(dataRow.visit_id,"checking")

   // opdSendIn

  }
  const handleSendIn=(dataRow)=>{
    console.log("dataroww handle sendnin",dataRow)
    opdStatus(dataRow.visit_id,"calling")
    console.log("In handle accept")
  }
  const handleDone=(dataRow)=>{
    console.log("In handle accept")

      opdStatus(dataRow.visit_id,"done")

   // opdSendIn

  }
  const handleKeepWaiting=(dataRow)=>{
    console.log("In handle accept")

      opdStatus(dataRow.visit_id,"waiting")

   // opdSendIn

  }
  const handlePatientDiagnosis=(dataRow)=>{
    console.log("In handle accept",dataRow)

    console.log("In handleModalPopup")
    setIsModal(true)
    setDataRow(dataRow)
    setModalTitle("Patient Name-"+ dataRow.full_name)

  }
  const handleCollectLabTestAmount=(dataRow)=>{
    console.log("In handle collect lab test amount",dataRow)

    console.log("In handleModalPopup")
    setIsModalLabTestPaymentCollection(true)
    setDataRow(dataRow)
    setModalTitle("Patient Name-"+ dataRow.full_name)

  }
  const showLabTestDetails=(dataRow)=>{
    console.log("lab test details",dataRow)  
    setIsModalLabTestDetails(true)
    if(role=="lab")
    {     
    setDataRow(dataRow.lab_tests[0])
    }
    else
    {
      setDataRow(dataRow)
     
    }
    setModalTitle("Lab Test Details")
  }
  const showDignosisDetails=(dataRow)=>{
   // console.log("Lab test details data",dataRow)
    setIsModalDiagnosisDetails(true)
    setDataRow(dataRow)
    setModalTitle("Diagnosis Details")
  }
  const showLabTestStatusDialog=(dataRow)=>{
    // console.log("Lab test details data",dataRow)
     setIsModalLabTestStatusDone(true)
     setDataRow(dataRow)
     setModalTitle("Mark lab test as done")
   }
  

  return (
    <>
      <ConfirmationModal
        confirmModal={handleDelete}
        hideModal={hideDialog}
        showModal={isConfirmDialog}
        message={"Record will be deleted permanently. Do you want to proceed?"}
      />
   {isPopupModal?   
   <TabsModal
      isOpen={isModal}
      setIsOpen={setIsModal}
      children={children}
      modal_title={modalTitle}
      data={datarow}
      size={"xl"}
      />:""    
      }
      {
        isPopupModalCollectLabTestAmount?      
        <TabsModal
        isOpen={isModalLabTestPaymentCollection}
        setIsOpen={setIsModalLabTestPaymentCollection}
        children={childrenCollectLabPayment}
        modal_title={modalTitle}
        data={datarow}
        size={"xl"}
        />:""
      }
      {
        isPopupModalLabTestDetails?
        <TabsModal
        isOpen={isModalLabTestDetails}
        setIsOpen={setIsModalLabTestDetails}
        children={childrenLabTestDetails}
        modal_title={modalTitle}
        data={datarow}       
        size={"l"}
        />:""
      }
{
  isPopupModalLabTestStatusDone?
  <TabsModal
    isOpen={isModalLabTestStatusDone}
    setIsOpen={setIsModalLabTestStatusDone}
    children={childrenLabTestStstusDone}
    modal_title={modalTitle}
    data={datarow}
    size={"xl"}
  />:""
}

  
   { isModalDiagnosisDetails? <TabsModal
        isOpen={isModalDiagnosisDetails}
        setIsOpen={setIsModalDiagnosisDetails}
        children={children}
        modal_title={modalTitle}
        data={datarow}
        size={"l"}
        />:""}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
          <TableRow>
              {columns.map((column_data, column_index) => {
                // Conditionally include "doctor_name" column only if the user's role is not 'doctor'
                if (!(column_data.key === 'doctor_name' && role === 'doctor')) {
                  return <TableCell key={column_data.key}>{column_data.title}</TableCell>;
                }
                return null;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((dataRow, rowIndex) => {
            //  console.log("Check datarow",dataRow)
               const isEmergency = dataRow.is_emergency_test;
              return (
                <TableRow key={rowIndex} className={isEmergency ? 'emergency-row add-space' : 'add-space'}>
                  {columns.map((columnKey, columnIndex) => {
                    if (columnIndex === 0 && role!=="tvdisplay") {
                      return (
                        <TableCell key={columnKey.key}>
                          {isEdit ? (
                            <a onClick={() => handleEdit(dataRow)} style={{ color: "green", cursor: 'pointer' }}>
                              <EditIcon style={{ fontSize: 'medium' }} />
                            </a>
                          ) : (
                            ""
                          )}
                          {isDelete ? (
                            <a onClick={() => confirmDialogOpen(dataRow)} style={{ color: "green", cursor: 'pointer', marginLeft: "10px" }}>
                              <DeleteIcon style={{ fontSize: 'medium' }} />
                            </a>
                          ) : (
                            ""
                          )}
                           {is_lab_test_data ? (
                            <a onClick={() => showLabTestDetails(dataRow)} style={{ color: "green", cursor: 'pointer', marginLeft: "10px" }}>
                              <InfoIcon style={{ fontSize: 'medium' }} />
                            </a>
                          ) : (
                            ""
                          )}
                           {is_lab_test_data  && role === 'lab' ? (
                            <a onClick={() => showLabTestStatusDialog(dataRow)} style={{ color: "green", cursor: 'pointer', marginLeft: "10px" }}>
                             <button>Done</button>
                            </a>
                          ) : (
                            ""
                          )}
                           {is_diagnosis_details ? (
                            <a onClick={() => showDignosisDetails(dataRow)} style={{ color: "green", cursor: 'pointer', marginLeft: "10px" }}>
                              <InfoIcon style={{ fontSize: 'medium' }} />
                            </a>
                          ) : (
                            ""
                          )}


                          {isInsidePage ? (
                            <a onClick={() => handleNavigate(dataRow)} style={{ color: "green", cursor: 'pointer', marginLeft: "10px" }}>
                              <ArrowForwardIcon style={{ fontSize: 'medium' }} />
                            </a>
                          ) : (
                            ""
                          )}
                           {isCasePaperButton ? (
                            <a onClick={() => handleModalPopup(dataRow)} style={{ color: "green", cursor: 'pointer', marginLeft: "10px" }}>
                             <button type="button" class="btn btn-primary btn-sm">Case Paper</button>
                            </a>
                          ) : (
                            ""
                          )}
                           {isAcceptButton && dataRow.visit_status=="calling" ? (
                            <a onClick={() => handleAccept(dataRow)} style={{ color: "green", cursor: 'pointer', marginLeft: "10px" }}>
                             <button type="button" class="btn btn-primary btn-sm">Accept</button>
                            </a>
                          ) : (
                            ""
                          )}
                           {isSendInButton ? (
                            <a onClick={() => handleSendIn(dataRow)} style={{ color: "green", cursor: 'pointer', marginLeft: "1px" }}>
                             <button type="button" class="btn btn-primary btn-sm">Send In</button>
                            </a>
                          ) : (
                            ""
                          )}
                          {/* {isDoneButton ? (
                            <a onClick={() => handleDone(dataRow)} style={{ color: "green", cursor: 'pointer', marginLeft: "5px" }}>
                             <button type="button" class="btn btn-primary btn-sm">Done</button>
                            </a>
                          ) : (
                            ""
                          )} */}

                          {isWaitingButton ? (
                            <a onClick={() => handleKeepWaiting(dataRow)} style={{ color: "green", cursor: 'pointer', marginLeft: "5px" }}>
                             <button type="button" class="btn btn-primary btn-sm">Keep Waiting</button>
                            </a>
                          ) : (
                            ""
                          )}
                           {isDiagnosisButton ? (
                            <a onClick={() => handlePatientDiagnosis(dataRow)} 
                            style={{ color: "green", cursor: 'pointer', marginLeft: "5px" }}>
                             <button type="button" class="btn btn-primary btn-sm">Diagnosis</button>
                            </a>
                          ) : (
                            ""
                          )}
                           {isCollectLabTestMoneyButton
                           && (dataRow.lab_test_money_status=="pending" || dataRow.lab_test_money_status=="received" )
                           ? 
                           (
                            <a onClick={() => handleCollectLabTestAmount(dataRow)} 
                            style={{ color: "green", cursor: 'pointer', marginLeft: "10px" }}>
                             <button type="button" class="btn btn-primary btn-sm">Collect Lab Test Payment</button>
                            </a>
                          ) : (
                            ""
                          )}


                          
                        </TableCell>
                      );
                    } else {
                      if (!(
                        columnKey.key === 'doctor_name' 
                      && (role === 'doctor' || role=="superadmin"))) {
                        return (
                        <>
                            <TableCell key={columnKey.key}>{dataRow[columnKey.key]}</TableCell>
                          </>
                        );
                      }
                      return null;                     
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination controls */}
    {is_table_pagination?  <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"       
        count={totalRows}
        rowsPerPage={rpg} 
        page={pg} 
        onPageChange={onPageChange}
        onRowsPerPageChange={handleChangeRowsPerPage} 
      />:""}
    </>
  );
};

export default DataTable;