import React,{useState,useEffect} from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleChangeRowsPerPage, handlePageChange } from '../Components/Pagination';
import { clearForm, handleBlur, handleChange, validateForm } from '../utility/formUtils';
import { validateDoctorForm } from '../utility/customValidation';
import { useAddDoctorMutation, useDeleteDoctorMutation, useEditDoctorMutation, useGetAllDoctorsQuery, useGetAllUsersQuery } from '../Services/modules/master'


const useDoctorForm = () => {
    const[doctorsData,setDoctorsData]=useState([])
    const[usersData,setUsersData]=useState([])

    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [isFormValid, setIsFormValid] = useState(false);
    const[isUpdate,setIsUpdate]=useState(false)
    const userRole = [
     
      { _id: 'doctor', method: 'doctor' },
      { _id: 'receptionist', method: 'receptionist' },
      { _id: 'lab', method: 'lab' },
      { _id: 'scanning', method: 'scanning' },
      { _id: 'tvdisplay', method: 'tvdisplay' },

  
      // Add more options as needed
    ];
    const[doctor_info,setDoctorInfo]=useState({
        role:"",
        _id:"",
        first_name:"",
        last_name:"",
        mobile_number:"",
        new_opd_fee:"",
        old_opd_fee:"",    
        opd_duration:""   
      })
      const initialDoctorInfo = {
        role:"doctor",
        first_name: "",
        last_name: "",
        mobile_number: "",
        new_opd_fee: "",
        old_opd_fee: "",
        opd_duration: "",
      };
      const{
        data:dataUsers=[],
        isSuccess:isSuccessUsers,
        isLoading:isLoadingUsers,
        error:errorUsers,
        refetch
    }=useGetAllUsersQuery({page_number:currentPage,page_size:rowsPerPage})
    const{
      data:dataDoctors=[],
      isSuccess:isSuccessDoctors,
      isLoading:isLoadingDoctors,
      error:errorDoctors,
      refetch:refetchDoctors
  }=useGetAllDoctorsQuery({page_number:currentPage,page_size:rowsPerPage})
      const [AddDoctor, 
        { 
        data, 
        isSuccess, 
        isLoading, 
        isError, 
        error }] = useAddDoctorMutation();
        const [EditDoctor, 
            { 
            data:dataEdit, 
            isSuccess:dataIsSuccess, 
            isLoading:dataIsLoading, 
            isError:dataIsError, 
            error:dataError }] = useEditDoctorMutation();
            const [DeleteDoctor, 
              { 
              data:dataDelete, 
              isSuccess:dataIsSuccessDelete, 
              isLoading:dataIsLoadingDelete, 
              isError:dataIsErrorDelete, 
              error:dataErrorDelete }] = useDeleteDoctorMutation();  
          const handlePageChangeWrapper = handlePageChange(setCurrentPage, refetch);
          const handleChangeRowsPerPageWrapper = handleChangeRowsPerPage(setRowsPerPage, setCurrentPage, refetch);
          useEffect(()=>{
            if(isSuccess)
                {
                  console.log("dataaaa",data)
                  if(data.error==true)
                  {
                     toast.error(data.message)
                  }
                  else
                  {
                    toast.success(data.message)
          
                  }
                   
                    clearForm(setDoctorInfo,initialDoctorInfo,setIsUpdate)
          
                }
          },[data])
          useEffect(()=>{
            if(isSuccessDoctors)
                {
                  console.log("dataDoctors.data",dataDoctors.data)
                  setDoctorsData(dataDoctors.data)
                }
          },[dataDoctors])
          useEffect(()=>{
            if(isSuccessUsers)
                {
                  console.log("dataDoctors.data",dataUsers.data)
                  setUsersData(dataUsers.data)
                }
          },[dataUsers])
          useEffect(()=>{
            if(dataIsSuccessDelete)
                {
                  console.log("dataaaa",dataDelete)
                  if(dataDelete.error==true)
                  {
                     toast.error(dataDelete.message)
                  }
                  else
                  {
                    toast.success(dataDelete.message)
          
                  }
                    clearForm(setDoctorInfo,initialDoctorInfo,setIsUpdate)
                }
          },[dataDelete])
          useEffect(()=>{
            if(dataIsSuccess)
                {
                  console.log("dataaaa",dataEdit)
                  if(dataEdit.error==true)
                  {
                     toast.error(dataEdit.message)
                  }
                  else
                  {
                    toast.success(dataEdit.message)
          
                  }
                    clearForm(setDoctorInfo,initialDoctorInfo,setIsUpdate)
                }
          },[dataEdit])
                  // const handleChangeWrapper = (e) => {
                  //   handleChange(e, doctor_info, setDoctorInfo,validateFormWrapper);
                  // };
                  const handleChangeWrapper = (e) => {
                    handleChange(e, doctor_info, setDoctorInfo,validateFormWrapper);
                    if (e.target.name === 'role') {
                      
                        setDoctorInfo((prevDoctorPaperInfo) => ({
                          ...prevDoctorPaperInfo,
                          new_opd_fee:"",
                          old_opd_fee:"",    
                          opd_duration:""   
                        }));
                      }
                    
                  };
                 
                  const handleBlurWrapper = () => {
                    handleBlur(() => validateFormWrapper());
                  };
                 
                    const validateFormWrapper = () => {
                      validateForm(doctor_info, setIsFormValid,validateDoctorForm);
                    };
                    
                    const onSubmit=()=>{
                      if(isUpdate)
                      {
                        EditDoctor(doctor_info)
                      }
                      else
                      {
                        AddDoctor(doctor_info)          
          
                      }
                  }
                  const doctorsDataWithFullName = doctorsData.map((doctor) => ({
                    ...doctor,
                    full_name: `${doctor.first_name} ${doctor.last_name}`,
                  }));
                  const usersDataWithFullName = usersData.map((users) => ({
                    ...users,
                    full_name: `${users.first_name} ${users.last_name}`,
                  }));
                  const updateDoctorInfo = (dataRow) => {   
                    setDoctorInfo({
                      ...doctor_info,
                      role:dataRow.role,
                      first_name:dataRow.first_name,
                      last_name:dataRow.last_name,
                      mobile_number:dataRow.mobile_number,
                      new_opd_fee:dataRow.new_opd_fee,
                      old_opd_fee:dataRow.old_opd_fee,    
                      opd_duration:dataRow.opd_duration ,
                      _id:dataRow._id,
                    });
                    setIsUpdate(true)
                    setIsFormValid(true)
                  };
                  const deleteDoctorInfo=(doctor_id)=>{
             
                    DeleteDoctor({_id:doctor_id})
                  }
                  return {
                    doctorsData,
                    usersData,
                    currentPage,
                    rowsPerPage,
                    totalRows,
                    isFormValid,
                    isUpdate,
                    doctor_info,
                    handleChangeWrapper,
                    handleBlurWrapper,
                    validateFormWrapper,
                    onSubmit,
                    handlePageChangeWrapper,
                    handleChangeRowsPerPageWrapper,
                    doctorsDataWithFullName,
                    usersDataWithFullName,
                    updateDoctorInfo,
                    deleteDoctorInfo,
                    setIsUpdate,
                    setDoctorInfo,
                    initialDoctorInfo,
                    userRole
                    // Add other state variables and functions here
                  };
          
}
export default useDoctorForm;
