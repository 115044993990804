import React from 'react'
import usePatientHistory from '../../../hooks/usePatientHistory'
import Select from 'react-select';
import { useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import TabsModal from '../../TabsModal';
import EditPatientVisit from '../EditPatientVisit';
import ConfirmationModal from '../../ConfirmationModal';
import { Config } from '../../../Config';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

function PatientHistory({data,doctorList}) {
    console.log("Data in patient history",data)
    const userData = useSelector((state) => state.userData.user_data);
    const userRole = userData ? userData.user.role : '';
    const patientVisits = useSelector((state) => state.patientVisitData.patient_visit_data);
  
    const{
      casePaperData,
      casepaper_id,
      setCasePaperId,
      getPatientVisits,
      casePaperVisitData,
      getPatientHistory,
      setVisitId,
      diagnosisDetails,
      labtestDetails,
      setDoctorId,
      doctor_id,
      handleDoctorSelect,
      casePaperDetails,
      casePaperVisitDetails,
      isEditPatientVisit,
      setIsEditPatientVisit,
      handleEditPatientVisit,
      patientVisitDataForEdit,
      handleDeletePatientVisit,
      handleDeleteConfirmDialogPatientVisit,
      isDeletePatientVisit,
      hideDeletepatientVisitConfirmDialog
    }= usePatientHistory({patientdata:data})
    console.log("casePaperVisitDetails check edit",casePaperVisitDetails)
    const options = casePaperData?.map((casepaper) => ({
      label: "Casepaper "+casepaper.case_paper_number+" "+casepaper.end_date,
      value: casepaper._id,
        
    })) || [];
    const optionsCasePaperVisits = casePaperVisitData?.map((casepapervisits) => ({
      label: "Visit "+casepapervisits.visit_number+" "+casepapervisits.visit_date,
      value: casepapervisits._id,
        
    })) || [];
  console.log("patientVisits from redux",patientVisits)
    
  return (
   <>
   <div className='row' style={{paddingBottom:"400px"}}>
    {
      isEditPatientVisit?
      <TabsModal
      setIsOpen={setIsEditPatientVisit}
      isOpen={isEditPatientVisit}
      children={<EditPatientVisit/>}
      data={patientVisitDataForEdit}
      />:""
    }
    {
      isDeletePatientVisit?
      <ConfirmationModal
      confirmModal={handleDeletePatientVisit}
      hideModal={hideDeletepatientVisitConfirmDialog}
      showModal={isDeletePatientVisit}
      message={"Record will be deleted permanently. Do you want to proceed?"}
      />:""
    }
 {userRole=="receptionist"?  
 <div className='col-md-3'>
   <div className="form-group">
      
      <label htmlFor="weight">Select Doctor</label>
      <select
className="form-control" // Bootstrap class for styling
value={doctor_id}
onChange={(e) => {
  setDoctorId(e.target.value);
  // Call your function here
  handleDoctorSelect(e.target.value);
}}

// onBlur={handleBlur}
name="doctor_id"
>
<option value="">Select Doctor</option>
{doctorList?.map((option, index) => (
<option key={option._id} value={option._id}>
{option.first_name+" "+option.last_name}
</option>
))}
</select>                
    </div>
   </div>:""}
    <div className='col-md-3'>
      
    <div className='form-group'>
    <label htmlFor='labTest'>Select Case Paper</label>
    <Select
               
                  options={options}                  
                 value={options.label}
                  onChange={(selectedOptions) => { 
                    console.log("selectedOptions",selectedOptions.value)
                    setCasePaperId(selectedOptions.value) 
                    getPatientVisits(selectedOptions.value)                                                     
                  }
                  }
                
                  name='lab_test'
                  placeholder='Select Case Paper'
                
                />
    </div>
    </div>
    <div className='col-md-3'>
    <div className='form-group'>
    <label htmlFor='labTest'>Select Visit</label>
    <Select
               
                  options={optionsCasePaperVisits}                  
                 value={optionsCasePaperVisits.label}
                  onChange={(selectedOptions) => { 
                    console.log("selectedOptions",selectedOptions.value)
                    setVisitId(selectedOptions.value)
                    //setCasePaperId(selectedOptions.value) 
                   // getPatientVisits(selectedOptions.value)                                                     
                  }
                  }
                
                  name='lab_test'
                  placeholder='Select Visit'
                  styles={{maxHeight:"200px"}}
                
                />
    </div>
    </div>
    <div className="col-md-3 d-flex align-items-end">
    <div className='form-group'>
                        <button 
          type="submit"
           onClick={()=>getPatientHistory()}
           className="btn btn-primary"
          // disabled={!isFormValid}
           >
            Submit
            {/* {isUpdate?"Update":" Save"} */}
           </button>
           </div>
           </div>
           <div className='col-md-12' style={{marginTop:"10px"}}>
         
         <table class="table table-bordered">
        {casePaperDetails.length>0?   <thead>
         <tr>
           <th colSpan={5} style={{textAlign:'center'}}>
             Casepaper Details
           </th>
         </tr>
           <tr>
        
         <th scope="col">Casepaper Number</th>
         <th scope="col">Start Date</th>
         <th scope="col">End Date</th>
         <th scope="col">Created Time</th>
         <th scope="col">Status</th>

       </tr>
           </thead>:""}
   <tbody>
     {
        casePaperDetails.map((data,index)=>{
         console.log("Datat of diagnosis details",data)
         return(
           <tr>
          
           <td>{data.case_paper_number}</td>
           <td>{data.start_date}</td>
           <td>{data.end_date}</td>
           <td>{data.created_time}</td>
           <td>{data.is_casepaper_expired?"INACTIVE":"ACTIVE"}</td>

         </tr>
         )
        })
     }
   </tbody>
         </table>             
              </div>
              <div className='col-md-12' style={{marginTop:"10px"}}>
         
         <table class="table table-bordered">
        {casePaperVisitDetails.length>0?   <thead>
         <tr>
           <th colSpan={11} style={{textAlign:'center'}}>
            Visit Details
           </th>
         </tr>
           <tr>
           <th scope="col">Action</th>
           <th scope="col">Visit Number</th>
         <th scope="col">Visit Date</th>
         <th scope="col">Visit Time</th>
         <th scope="col">Have An Issurance</th>
         <th scope="col">Reffer By Dr</th>
         <th scope="col">Fee</th>
         <th scope="col">Payment Method</th>
         <th scope="col">Online</th>
         <th scope="col">Online Transaction Number</th>
         <th scope="col">Cash</th>

       
       </tr>
           </thead>:""}
   <tbody>
     {
       // casePaperVisitDetails.map((data,index)=>{
        patientVisits?.map((data,index)=>{

         console.log("Datat of diagnosis details",data)
         return(
           <tr>
            <td>
            <a 
            onClick={() => handleEditPatientVisit(data)} 
            style={{ color: "green", cursor: 'pointer' }}>
            <EditIcon style={{ fontSize: 'medium' }} />
                            </a>
                            <a 
            onClick={() => handleDeleteConfirmDialogPatientVisit(data)} 
            style={{ color: "green", cursor: 'pointer' }}>
            <DeleteIcon style={{ fontSize: 'medium' }} />
                            </a>
            </td>
           <td>{data.visit_number}</td>
           <td>{data.visit_date}</td>
           <td>{data.visit_time}</td>          
           <td>{data.is_inssured?"YES":"NO"}</td>
           <td>{data.ref_dr}</td>   
           <td>{data.fee}</td>    
           <td>{data.payment_method}</td>  
           <td>{data.online_amount}</td>  
           <td>{data.online_ref_number}</td>  
           <td>{data.cash_in_amount}</td>  

         </tr>
         )
        })
     }
   </tbody>
         </table>             
              </div>
           <div className='col-md-12' style={{marginTop:"10px"}}>
         
      <table class="table table-bordered">
     {diagnosisDetails.length>0?   <thead>
      <tr>
        <th colSpan={5} style={{textAlign:'center'}}>
          Diagnosis Details
        </th>
      </tr>
        <tr>
      <th scope="col">#</th>
      <th scope="col">Diagnosis</th>
      <th scope="col">Advice</th>
      <th scope="col">Is Surgery</th>
      <th scope="col">Surgery Details</th>
    </tr>
        </thead>:""}
<tbody>
  {
     diagnosisDetails.map((data,index)=>{
      console.log("Datat of diagnosis details",data)
      return(
        <tr>
        <th scope="row">{index+1}</th>
        <td>{data.diagnosis}</td>
        <td>{data.advice}</td>
        <td>{data.is_surgery}</td>
        <td>{data.surgery_details}</td>
      </tr>
      )
     })
  }
</tbody>
      </table>             
           </div>
           <div className='col-md-12'>
         
<table class="table table-bordered">
      
       {labtestDetails.length>0?
         <thead>
         <tr>
         <th colSpan={3} style={{textAlign:'center'}}>
           Test Details
         </th>
       </tr>
        <tr>
      <th scope="col">#</th>
      <th scope="col">Test Name</th>
      <th scope="col">Price</th>
   
    </tr>
     </thead>
    :""}
       
<tbody>

  {
     labtestDetails?.map((labtestdata,index)=>{     
      return(
        <>        
        
          {
            labtestdata?.test_details.map((test_name,index)=>{
             return(
               <tr>
                <td>{index+1}</td>
                <td>{test_name.test_name}</td>
                <td>{test_name.test_price}</td>
              </tr>
              )
            })
          } 
            <tr>
          <th colspan="3">
         Test Description*: {labtestdata.test_description}
          </th>        
          </tr>
          <tr>
          <th colspan="3">
        <a href={`${Config.API_URL_FILE}/LabReports/${labtestdata.test_report_file}`} target='_blank'>
        <InsertDriveFileIcon style={{ fontSize: 'medium' }} />
        </a>
     
        {/* {test_file} */}
        </th>
          </tr>
          <tr>
          <th colspan="3">
          Test Note By Lab*: {labtestdata.test_report_note}
        {/* {test_file} */}
        </th>
          </tr>
        </>
     
          
      )        
     })   
  }
</tbody>
      </table>    
           </div>
   </div>
  
   </>
  )
}

export default PatientHistory