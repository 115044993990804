import React from 'react'
import Template from '../Components/Template'

function PatientDiagnosisDetails() {
const patientDiagnosisDetailsScreen=()=>{
    return(
        <>
        <div className='row'>
          <div className='col-md-6'>
            <div className='card'>
              <div className='card-body'>
              <h5 className="card-title">Add Diagnosissss</h5>
              <div className="form-group">
                  <label htmlFor="first_name">Diagnosis</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="first_name"
                  name="first_name"
                  // value={doctor_info.first_name}
                  //  onChange={handleChangeWrapper}
                  //  onBlur={handleBlurWrapper}
                   />
                </div> 
                <div className="form-group">
                  <label htmlFor="first_name">Advice</label>
                  <input type="text" 
                  className="form-control mb-3" 
                  id="first_name"
                  name="first_name"
                  // value={doctor_info.first_name}
                  //  onChange={handleChangeWrapper}
                  //  onBlur={handleBlurWrapper}
                   />
                </div> 
                <div className="mt-3 form-group">
                        <div className="form-check">
                      <input type="checkbox"
                       className="form-check-input" 
                       id="termsCheckbox" 
                       name="is_inssured"
                      // checked={patientInfo.is_inssured} 
                      // onChange={handleChangeWrapper} 
                      />
                      <label className="form-check-label" htmlFor="termsCheckbox">
                        Is Surgery
                      
                      </label>
                      
                    </div>
                        </div>
              </div>
            </div>
          </div>
        </div>
        </>
    )
}
  return (
   <Template>{patientDiagnosisDetailsScreen()}</Template>
  )
}

export default PatientDiagnosisDetails