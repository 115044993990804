import { useEffect, useState } from "react";
import { useDeletePatientVisitMutation, useEditCasePaperVisitMutation } from "../Services/modules/patient";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleChange, validateForm } from "../utility/formUtils";
import { validateCasePaperForm } from "../utility/customValidation";
import usePatientHistory from "./usePatientHistory";
import { useDispatch } from "react-redux";
import { updatePatientVisitData } from "../Store/PatientVisitsData";
const useEditPatientVisit=(patientVisitData)=>{
  const dispatch=useDispatch()

  const [isFormValid, setIsFormValid] = useState(false);
 
const [casePaperInfo, setCasePaperInfo] = useState({
    _id:patientVisitData._id,      
    fee:patientVisitData.fee,
    payment_method:patientVisitData.payment_method,
    online_ref_number:patientVisitData.online_ref_number,
    online_amount:patientVisitData.online_amount,
    cash_in_amount:patientVisitData.cash_in_amount,   
    ref_dr:patientVisitData.ref_dr,
    is_inssured: patientVisitData.is_inssured,  
  });
  const [EditCasePaper, 
    { 
    data:editVisitData, 
    isSuccess:editVisitSuccess, 
    isLoading:editVisitIsLoading, 
    isError:editVisitIsError, 
    error:editVisitError }] = useEditCasePaperVisitMutation();
   
    const paymentMethods = [
        { _id: 'online', method: 'online' },
        { _id: 'cash', method: 'cash' },
        { _id: 'both', method: 'both' },
    
        // Add more options as needed
      ];
  const editVisit = () => {  
    if(casePaperInfo.payment_method=="both")
    {
      const totalAmount = parseInt( casePaperInfo.online_amount) + parseInt( casePaperInfo.cash_in_amount);
      if (totalAmount !== parseInt(casePaperInfo.fee)) {
       
       return toast.error('Online and cash amount not matching total fee');
      } 
    }
 
    EditCasePaper({data:casePaperInfo})       
}
const handleChangeWrapper = (e) => {
    handleChange(e, casePaperInfo, setCasePaperInfo,validateFormWrapper);
    if (e.target.name === 'payment_method') {
        // If the payment_method changes, reset the related fields
        if(e.target.value=="online")
        {
          setCasePaperInfo((prevCasePaperInfo) => ({
            ...prevCasePaperInfo,
            online_ref_number: '',
            online_amount: casePaperInfo.fee,
            cash_in_amount: '',
          }));
        }
        if(e.target.value=="cash")
        {
          setCasePaperInfo((prevCasePaperInfo) => ({
            ...prevCasePaperInfo,
            online_ref_number: '',
            online_amount: '',
            cash_in_amount: casePaperInfo.fee,
          }));
        }
        if(e.target.value=="both")
        {
          setCasePaperInfo((prevCasePaperInfo) => ({
            ...prevCasePaperInfo,
            online_ref_number: '',
            online_amount: '',
            cash_in_amount: '',
          }));
        }     
      }            
  };
  const validateFormWrapper = () => {
    validateForm(casePaperInfo, setIsFormValid,validateCasePaperForm);
  };
  const onSubmitEditVisit=()=>{
    if(casePaperInfo.payment_method=="both")
    {
      const totalAmount = parseInt( casePaperInfo.online_amount) + parseInt( casePaperInfo.cash_in_amount);
      if (totalAmount !== parseInt(casePaperInfo.fee)) {
       
       return toast.error('Online and cash amount not matching total fee');
      } 
    }
 
    EditCasePaper({casePaperInfo:casePaperInfo})       
  }
 
  useEffect(()=>{
    if(editVisitSuccess)
    {
        console.log("Data after edit",editVisitData)
        if(editVisitData.error)
        {
            return toast.error(editVisitData.message)
        }
        toast.success(editVisitData.message)
    dispatch(updatePatientVisitData({data:editVisitData.data}))

       // setCasePaperVisitDetails(editVisitData.data)

    }
  },[editVisitData, editVisitSuccess])
  return {   
    casePaperInfo,
    editVisit,
    paymentMethods,
    handleChangeWrapper,
    editVisitIsLoading,
    onSubmitEditVisit,
   
  };
}
export default useEditPatientVisit;
