import { useState, useEffect } from 'react';
import { useAddPatientDiagnosisMutation, useGetPatientDiagnosisByPatientAndDoctorIdQuery } from '../Services/modules/patient';
import { handleChangeRowsPerPage, handlePageChange } from '../Components/Pagination';
import { handleBlur, handleChange, validateForm } from '../utility/formUtils';
import { validateDiagnosisForm } from '../utility/customValidation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const usePatientDiagnosisForm=({patientdata})=>{
    console.log("patientdata",patientdata)
    const[diagnosisData,setDiagnosisData]=useState([])
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [isFormValid, setIsFormValid] = useState(false);
    const[isUpdate,setIsUpdate]=useState(false)
    const[patientDiagnosisInfo,setPatientDiagnosisInfo]=useState({       
        _id:"",
        visit_id:patientdata.visit_id,
        patient_id:patientdata.patient_id,
        diagnosis:"",
        advice:"",
        is_surgery:"",
        surgery_details:""     
      })
      const initialDiagnosisInfo = {
        _id:"",
        visit_id:"",
        patient_id:"",
        diagnosis:"",
        advice:"",
        is_surgery:false,
        surgery_details:""   
      };
      const{
        data:dataDiagnosis=[],
        isSuccess:isSuccessDiagnosis,
        isLoading:isLaodingDiagnosis,
        error:errorDiagnosis,
        refetch
    }=useGetPatientDiagnosisByPatientAndDoctorIdQuery({page_number:currentPage,page_size:rowsPerPage,patient_id:patientdata.patient_id})
    const [AddPatientDiagnosis, 
        { 
        data, 
        isSuccess, 
        isLoading, 
        isError, 
        error }] = useAddPatientDiagnosisMutation();
        const handlePageChangeWrapper = handlePageChange(setCurrentPage, refetch);
        const handleChangeRowsPerPageWrapper = handleChangeRowsPerPage(setRowsPerPage, setCurrentPage, refetch);

        useEffect(()=>{
            if(isSuccess)
                {
                  console.log("dataaaa",data)
                  if(data.error==true)
                  {
                     toast.error(data.message)
                  }
                  else
                  {
                    toast.success(data.message)
          
                  }
                   
                  //  clearForm(setDoctorInfo,initialDoctorInfo,setIsUpdate)
          
                }
          },[data])

          useEffect(()=>{
            if(isSuccessDiagnosis)
                {
                  console.log("dataDiagnosis.data",dataDiagnosis.data)
                  setDiagnosisData(dataDiagnosis.data)
                }
          },[dataDiagnosis])
          const validateFormWrapper = () => {
            validateForm(patientDiagnosisInfo, setIsFormValid,validateDiagnosisForm);
          };
          const handleChangeWrapper = (e) => {
            handleChange(e, patientDiagnosisInfo, setPatientDiagnosisInfo,validateFormWrapper);                       
          };
          const handleBlurWrapper = () => {
            handleBlur(() => validateFormWrapper());
          };
          const onSubmit=()=>{
            // if(isUpdate)
            // {
            //   EditDoctor(doctor_info)
            // }
            // else
            // {
              AddPatientDiagnosis({data:patientDiagnosisInfo})          

           // }
        }

        return {
            diagnosisData,
            currentPage,
            rowsPerPage,
            totalRows,
            isFormValid,
            isUpdate,
            patientDiagnosisInfo,
            handleChangeWrapper,
            handleBlurWrapper,
            validateFormWrapper,
            onSubmit,
            handlePageChangeWrapper,
            handleChangeRowsPerPageWrapper,                    
            setIsUpdate,
            setPatientDiagnosisInfo,
            initialDiagnosisInfo,          
          };
}
export default usePatientDiagnosisForm;
