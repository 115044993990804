import React from 'react'
import useEditPatientVisit from '../../hooks/useEditPatientVisit'
import useCasePaperForm from '../../hooks/useCasePaperForm'

function EditPatientVisit({data}) {
    console.log("Data in edit patient visit",data)
    const{
        casePaperInfo,
        editVisit,
        paymentMethods,
        handleChangeWrapper,
        editVisitIsLoading,
        onSubmitEditVisit
    }=useEditPatientVisit(data)
   
  return (
   <>
    <div className='row'>
       <div className="form-group">
       <div className="form-group">
                          <label htmlFor="fee">Fee</label>
                          <input
                            type="text"
                            className="form-control"
                            id="fee"
                            name="fee"
                            value={casePaperInfo.fee}
                           onChange={handleChangeWrapper}
                          />
                        </div>
                        <div className="form-group mt-3">
      <label htmlFor="doctor">Select Payment Method</label>
      <select
        className="form-control"
        value={casePaperInfo.payment_method}
        onChange={handleChangeWrapper}
        name="payment_method"
      >
        <option value="">Payment Method</option>
        {paymentMethods.map((payment) => (
          <option key={payment._id} value={payment._id}>
            {`${payment.method}`}
          </option>
        ))}
      </select>
    </div>
    <div className="form-group mt-3">
                          <label htmlFor="first_name">Online Reference Number</label>
                          <input
                            type="text"
                            className="form-control"
                            id="online_ref_number"
                            name="online_ref_number"
                            disabled={casePaperInfo.payment_method !== 'online' && casePaperInfo.payment_method !== 'both'}
                            value={casePaperInfo.online_ref_number}
                            onChange={handleChangeWrapper}
                          />
                        </div>
                        <div className="form-group mt-3">
                          <label htmlFor="first_name">Online Amount</label>
                          <input
                            type="text"
                            className="form-control"
                            id="online_amount"
                            name="online_amount"
                            disabled={casePaperInfo.payment_method !== 'online' && casePaperInfo.payment_method !== 'both'}
                            value={casePaperInfo.online_amount}
                            onChange={handleChangeWrapper}
                          />
                        </div>
                        <div className="form-group mt-3">
                          <label htmlFor="first_name">Cash Amount</label>
                          <input
                            type="text"
                            className="form-control"
                            id="cash_in_amount"
                            name="cash_in_amount"
                            disabled={casePaperInfo.payment_method !== 'cash' && casePaperInfo.payment_method !== 'both'}
                            value={casePaperInfo.cash_in_amount}
                            onChange={handleChangeWrapper}
                          />
                        </div>
                        <div className="form-group mt-3">
                        <div className="form-check">
                      <input type="checkbox"
                       className="form-check-input"                       
                       name="is_inssured"
                      checked={casePaperInfo.is_inssured} 
                      onChange={handleChangeWrapper} />
                      <label className="form-check-label" 
                      htmlFor="termsCheckbox">
                        Have an inssurance
                      
                      </label>
                      
                    </div>
                        </div>
                        <div className="mt-3 form-group">
                        <button
                          type="submit"
                          onClick={onSubmitEditVisit}
                          className="btn btn-success"
                         // disabled={!isFormValid}
                         disabled={editVisitIsLoading}

                        >
                          {editVisitIsLoading?
                          <div class="spinner-border spinner-border-sm" role="status"/>:""}
                        Edit OPD Visit
                        </button>
                      </div>
       </div>
       </div>
   </>
  )
}

export default EditPatientVisit