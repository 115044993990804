import React,{useState} from 'react'
import usePatientTests from '../../../hooks/usePatientTests';
import Select from 'react-select';
import { labTestColumns } from '../../../utility/TableColumns';
import DataTable from '../../DataTable';
import LabTestDetails from '../LabTestDetails';

function PatientLabTest({data}) {
  //console.log()
  const{
    labTestData,
    scanningData,
    patientLabTestInfo ,
    handleChangeWrapper,
    setPatientLabTestInfo,  
    patientLabTestData,
    onSubmitLabTest,
    setSelectedTestDetails,
    selectedTestDetails
  }= usePatientTests({patientdata:data})
 //console.log("labTestData",labTestData)
  const options = labTestData?.map((test) => ({
    label: test.test_name+"-"+test.test_price,
    value: test.test_name,
    test_price: test.test_price,

  })) || []; 
  return (
    <>
    <div className='row'>
 <div className='col-md-7'>
   <div className='card'>
     <div className='card-body'>
     <h5 className="card-title">Add Lab Test</h5>
     <div className='form-group'>
                <label htmlFor='labTest'>Lab Test</label>
              
                <Select
                isMulti  
                  options={options}                  
                  value={patientLabTestInfo.test_details}
                  onChange={(selectedOptions) => {                  
                    const totalPrice = selectedOptions.reduce(
                      (sum, option) => sum + parseFloat(option.test_price),
                      0
                    );
                    setPatientLabTestInfo((prevLabTestInfo) => ({
                      ...prevLabTestInfo,
                      total_payment: totalPrice.toString(), 
                      test_details:  selectedOptions                                 
                    }))
                  }
                  }
                  //onChange={handleChangeWrapper}
                  name='lab_test'
                  placeholder='Select Lab Test'
                  isSearchable
                />
              </div>
              <div className="form-group mt-3">
                          <label htmlFor="total_payment">Test Price</label>
                          <input
                            type="text"
                            className="form-control"
                            id="total_payment"
                            name="total_payment"
                            value={patientLabTestInfo.total_payment}
                            onChange={handleChangeWrapper}
                          />
                        </div>
                        <div className="form-group mt-3">
                          <label htmlFor="test_description">Test Description</label>
                          <input
                            type="text"
                            className="form-control"
                            id="test_description"
                            name="test_description"
                            value={patientLabTestInfo.test_description}
                            onChange={handleChangeWrapper}
                          />
                        </div>
                        <div className="mt-3 form-group">
                        <div className="form-check">
                      <input type="checkbox"
                       className="form-check-input"                     
                       name="is_emergency_test"
                       checked={patientLabTestInfo.is_emergency_test} 
                       onChange={handleChangeWrapper} 
                      />
                      <label className="form-check-label">
                        Is Emergency
                      
                      </label>
                      
                    </div>
                        </div>

                        <div className="mt-3 form-group">
                        <button
                          type="submit"
                          onClick={onSubmitLabTest}
                          className="btn btn-success"
                         // disabled={!isFormValid}
                        // disabled={casePaperLoading}

                        >
                          {/* {casePaperLoading?
                          <div class="spinner-border spinner-border-sm" 
                          role="status"/>:""} */}

                          Create Test
                        </button>
                      </div>
     </div>
   </div>
 </div>
 <div className='col-md-5'>
          <div className='card'>
    <div className='card-body'>
        <DataTable
        data={patientLabTestData}
        columns={labTestColumns}
        is_lab_test_data={true}       
        isPopupModalLabTestDetails={true}
        childrenLabTestDetails={<LabTestDetails/>}
        isEdit={false}
        isDelete={false}
       // totalRows={totalRows}
      //  rpg={rowsPerPage}
      //  pg={currentPage}
       // updateData={updateDoctorInfo}
       // deleteData={deleteDoctorInfo}
       // onPageChange={handlePageChangeWrapper}
      //  handleChangeRowsPerPage={handleChangeRowsPerPageWrapper}
        />
    </div>
</div>
          </div>
</div>


   </>
  )
}

export default PatientLabTest