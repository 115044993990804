export default build =>
    build.query({
        tagTypes: ['patientDiagnosis'],
        keepUnusedDataFor: 1,
        query(payload) {   
        console.log("In get patient diagnosis")

           
            return {
                url: `/patient/getPatientDiagnosisByPatientIdAndDoctorId?patient_id=${payload.patient_id}`,                
                method: 'GET',              
            };
           
        },
       providesTags: ['patientDiagnosis']
    })
