import React from 'react'
import Template from '../Components/Template'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';

function ReportDoctorLanding() {
  const userData = useSelector((state) => state.userData.user_data);
  console.log("userData",userData.user.role)
  const user_id=userData.user._id
  const doctor_name=userData.user.first_name+" "+userData.user.last_name
  let role=userData.user.role
    const ReportDoctorLandingScreen=()=>{
        return(
            <>
           
           <div className="d-flex justify-content-center align-items-center">
           <Link 
             to={{
              pathname: '/reportopdcollection',
              arch: `?user_id=${user_id}&doctor_name=${doctor_name}` 
          }}
    // to='/reportopdcollection'
    style={{textDecoration:'none'}}
    >
        <div className="card text-center m-3" style={{ width: '18rem' }}>
          <div className="card-body">
            <h5 className="card-title">OPD Collection</h5>
          </div>
        </div>
        </Link>
        <Link 
          to={{
            pathname: '/reportpatientvisits',
            search: `?user_id=${user_id}&doctor_name=${doctor_name}` 
        }}
  
    style={{textDecoration:'none'}}
    >
        <div className="card text-center m-3" style={{ width: '18rem' }}>
          <div className="card-body">
            <h5 className="card-title">Patient Visits</h5>
          </div>
        </div>
        </Link>

      
      </div>
           
        
            </>
        )
       
    }
  return (
    <Template>{ReportDoctorLandingScreen()}</Template>
  )
}

export default ReportDoctorLanding