import { useState, useEffect } from 'react';
import { useChangeOpdPatientStatusMutation, useGetPatientQueueQuery } from '../Services/modules/patient';
import { useGetAllDoctorsQuery } from '../Services/modules/master';
import { handleChange, validateForm } from '../utility/formUtils';
import { validateCasePaperForm } from '../utility/customValidation';
import io from 'socket.io-client';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Config } from '../Config';
import { handleChangeRowsPerPage, handlePageChange } from '../Components/Pagination';

const usePatientOpdQueue=({doctor_id})=>{
  // const socket = io('http://localhost:8000');
  const socket = io(Config.API_URL_WEBSOCKET, {
    reconnection: true,
  reconnectionAttempts: 3,  
  reconnectionDelay: 1000,  
  });

 // console.log("Doctoridddddd",doctor_id)

    const[opdList,setOpdList]=useState([])
  const [doctorList, setDoctorList] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [doctorInfo, setDoctorInfo] = useState({      
    doctor_id:"",  
  });
  const [changeStatusInfo, setChangeStatusInfo] = useState({      
    visitId:"",  
    newVisitStatus:""
  });
    const{
        data,
        isSuccess,
        isLoading,
        error,
        refetch
    }=useGetPatientQueueQuery({doctor_id:doctor_id})

  

    const{
        data:dataDoctors=[],
        isSuccess:isSuccessDoctors,
        isLoading:isLoadingDoctors,
        error:errorDoctors,
        //refetch
    }=useGetAllDoctorsQuery({page_number:0,page_size:100})
    const [ChangeOpdPatientStatus, 
      { 
      data:opdStatusData, 
      isSuccess:opdStatusIsSuccess, 
      isLoading:opdStatusIsLoading, 
      isError:opdStatusIsError, 
      error:opdStatusError }] = useChangeOpdPatientStatusMutation();
    useEffect(()=>{
        console.log("All OPD Queue",data)
        if(isSuccess)
        {        
            setOpdList(data.data)            
        }
      },[data])    
      useEffect(()=>{
      //  console.log("All Scheme data",dataDoctors)
        if(isSuccessDoctors)
        {
        
            setDoctorList(dataDoctors.data)
            
        }
      },[dataDoctors])
      useEffect(()=>{
        if(opdStatusIsSuccess)
        {
          console.log('Status Data:', opdStatusData);
          console.log("Socket connected:", socket.connected);            
            if(opdStatusData.error)
            {
                toast.error(opdStatusData.message)
            }  
            else
            {             
              toast.success(opdStatusData.message)
              console.log("After status change messahe")
              socket.on('patientStatusChanged', (opdStatusData) => {
                console.log('In status change web socket', opdStatusData);
                socket.emit('patientStatusChanged', opdStatusData);
                refetch()
                // Update your user table or trigger any other actions here
              });
            }                                     
        }
        // return () => {
        
        //   socket.off('patientStatusChanged');       
        //  // socket.disconnect();        
        // };
      },[opdStatusData])
      useEffect(() => {
        // Listen for the 'newCasePaper' socket event
        if (refetch) {
        socket.on('newCasePaper', (casePaperData) => {
          console.log('New case paper:', casePaperData);
          // Trigger data refresh when a new case paper is received
          //refreshOpdData();
          if (refetch) {
            refetch();
          }
        });
        socket.on('patientStatusChanged', (opdStatusData) => {
          console.log('Opd patient status change socket', opdStatusData);
          // Trigger data refresh when a new case paper is received
          //refreshOpdData();
          if (refetch) {
            refetch();
          }
        });
        socket.on('patientLabTestAddedQueue', (opdLabData) => {
          console.log('Opd patient lab test given', opdLabData);
          // Trigger data refresh when a new case paper is received
          //refreshOpdData();
          if (refetch) {
            refetch();
          }
        });
      }
        // Clean up the socket listener when the component unmounts
        return () => {
          socket.off('newCasePaper');
         socket.off('patientStatusChanged');
          socket.off('patientLabTestAddedQueue');
         // socket.disconnect();        

        };
      }, []);
     
      const handleChangeWrapper = (e) => {
        handleChange(e, doctorInfo, setDoctorInfo,validateFormWrapper);
      
      };
      const opdData = opdList.map((opd) => ({
        ...opd,
        lab_test_money: `${opd.lab_test_money!==undefined?opd.lab_test_money:""}  ${opd.lab_test_money_status!==undefined?" | "+opd.lab_test_money_status:"NA"}  ${opd.is_emergency_test?"| Emergency":""}`,
        lab_test_total_fee:`${opd.lab_test_money}`
      }));
      const validateFormWrapper = () => {
        validateForm(doctorInfo, setIsFormValid,validateCasePaperForm);
      };
      const onSubmitChangeOpdPatientStatus = (visit_id,visit_status) => { 
      //  console.log("Visitid in change opd status",visit_id)
      //  console.log("visit status in change opd status",visit_status)

       ChangeOpdPatientStatus({visit_id,visit_status})      
      }
     
      return {   
        opdList:opdData  ,
        doctorList  ,
        doctorInfo,
        handleChangeWrapper,
        onSubmitChangeOpdPatientStatus,
        changeStatusInfo  ,
        refetch ,
      
        // Add other state variables and functions here
      };
}
export default usePatientOpdQueue;
