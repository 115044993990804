import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

function Sidebar() {
  const location=useLocation()
  const userData = useSelector((state) => state.userData.user_data);
  const userRole = userData ? userData.user.role : '';

 // console.log("Userrole in sidebar",userRole)
  const getNavLinkStyle = (pathname) => {
    return {
      textDecoration: 'none',
     // backgroundColor: location.pathname === pathname ? '#f0f0f0' : 'white',
     backgroundColor: pathname.some((path) => location.pathname === path) ? '#f0f0f0' : 'white',
    };
  };
  const renderAdminMenu = () => {
    if (userRole === 'superadmin') {
      return (
        <>
          <Link to="/patientlanding" style={{ textDecoration: 'none' }}>
            <li class="nav-item">
              <a class="nav-link" style={getNavLinkStyle(['/patientlanding', '/addpatient'])}>
                <i class="bi bi-grid"></i>
                <span>Patients</span>
              </a>
            </li>
          </Link>
          <Link to="/masterlanding" style={{ textDecoration: 'none' }}>
            <li class="nav-item">
              <a class="nav-link" style={getNavLinkStyle(['/masterlanding', '/adddoctor'])}>
                <i class="bi bi-grid"></i>
                <span>Master Entries</span>
              </a>
            </li>
          </Link>
        
          {/* <Link to="/products" style={{ textDecoration: 'none' }}>
            <li class="nav-item">
              <a class="nav-link" style={getNavLinkStyle(['/products'])}>
                <i class="bi bi-grid"></i>
                <span>Reports</span>
              </a>
            </li>
          </Link> */}
           <Link to="/reportdoctorlanding" style={{ textDecoration: 'none' }}>
            <li class="nav-item">
              <a class="nav-link" style={getNavLinkStyle(['/reportdoctorlanding'])}>
                <i class="bi bi-grid"></i>
                <span>Reports</span>
              </a>
            </li>
          </Link>
        </>
      );
    }
    return null;
  };
  const renderDoctorMenu = () => {
    if (userRole === 'doctor') {
      return (
        <>
          <Link to="/opdlanding" style={{ textDecoration: 'none' }}>
            <li class="nav-item">
              <a class="nav-link" style={getNavLinkStyle(['/opdlanding', '/adddoctor'])}>
                <i class="bi bi-grid"></i>
                <span>OPD</span>
              </a>
            </li>
          </Link>
          <Link to="/reportdoctorlanding" style={{ textDecoration: 'none' }}>
            <li class="nav-item">
              <a class="nav-link" style={getNavLinkStyle(['/reportdoctorlanding'])}>
                <i class="bi bi-grid"></i>
                <span>Reports</span>
              </a>
            </li>
          </Link>
         
        </>
      );
    }
    return null;
  };
  const renderTvDisplayMenu = () => {
    if (userRole === 'tvdisplay') {
      return (
        <>
          <Link to="/patientlanding" style={{ textDecoration: 'none' }}>
            <li class="nav-item">
              <a class="nav-link" style={getNavLinkStyle(['/patientlanding', '/addpatient'])}>
                <i class="bi bi-grid"></i>
                <span>Patients</span>
              </a>
            </li>
          </Link>
         
        
        
        </>
      );
    }
    return null;
  };
  const renderReceptionistMenu = () => {
    if (userRole === 'receptionist') {
      return (
        <>
          <Link to="/opdlanding" style={{ textDecoration: 'none' }}>
            <li class="nav-item">
              <a class="nav-link" style={getNavLinkStyle(['/opdlanding', '/addpatient'])}>
                <i class="bi bi-grid"></i>
                <span>OPD</span>
              </a>
            </li>
          </Link>
          <Link to="/reportdoctorlanding" style={{ textDecoration: 'none' }}>
            <li class="nav-item">
              <a class="nav-link" style={getNavLinkStyle(['/reportdoctorlanding'])}>
                <i class="bi bi-grid"></i>
                <span>Reports</span>
              </a>
            </li>
          </Link>
          {/* <Link to="/products" style={{ textDecoration: 'none' }}>
            <li class="nav-item">
              <a class="nav-link" style={getNavLinkStyle(['/products'])}>
                <i class="bi bi-grid"></i>
                <span>Reports</span>
              </a>
            </li>
          </Link> */}
          {/* <Link to="/masterlanding" style={{ textDecoration: 'none' }}>
            <li class="nav-item">
              <a class="nav-link" style={getNavLinkStyle(['/masterlanding', '/adddoctor'])}>
                <i class="bi bi-grid"></i>
                <span>Master Entries</span>
              </a>
            </li>
          </Link>
        
          <Link to="/products" style={{ textDecoration: 'none' }}>
            <li class="nav-item">
              <a class="nav-link" style={getNavLinkStyle(['/products'])}>
                <i class="bi bi-grid"></i>
                <span>Reports</span>
              </a>
            </li>
          </Link> */}
        </>
      );
    }
    return null;
  };
  const renderLabMenu = () => {
    if (userRole === 'lab') {
      return (
        <>
          <Link to="/labtestqueue" style={{ textDecoration: 'none' }}>
            <li class="nav-item">
              <a class="nav-link" style={getNavLinkStyle(['/labtestqueue'])}>
                <i class="bi bi-grid"></i>
                <span>LAB Tests</span>
              </a>
            </li>
          </Link>
          {/* <Link to="/masterlanding" style={{ textDecoration: 'none' }}>
            <li class="nav-item">
              <a class="nav-link" style={getNavLinkStyle(['/masterlanding', '/adddoctor'])}>
                <i class="bi bi-grid"></i>
                <span>Master Entries</span>
              </a>
            </li>
          </Link>
        
          <Link to="/products" style={{ textDecoration: 'none' }}>
            <li class="nav-item">
              <a class="nav-link" style={getNavLinkStyle(['/products'])}>
                <i class="bi bi-grid"></i>
                <span>Reports</span>
              </a>
            </li>
          </Link> */}
        </>
      );
    }
    return null;
  };
  
  return (
    <>
      <aside id="sidebar" class="sidebar">
        <ul class="sidebar-nav" id="sidebar-nav">
          <Link to="/dashboard" style={{ textDecoration: 'none' }}>
            <li class="nav-item">
              <a class="nav-link" style={getNavLinkStyle(['/dashboard'])}>
                <i class="bi bi-grid"></i>
                <span>Dashboard</span>
              </a>
            </li>
          </Link>
        
          {renderAdminMenu()}
          {renderDoctorMenu()}
          {renderTvDisplayMenu()}
          {renderReceptionistMenu()}
          {renderLabMenu()}

        </ul>
      </aside>
    </>
  );

 
}

export default Sidebar